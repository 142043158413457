import axios from 'axios';

const url = process.env.REACT_APP_SCAN_FILE_API_URL;

export const scanFileApi = {
    getToken: () =>
        axios.post(url + '/Token', {
            username: process.env.REACT_APP_SCAN_FILE_API_USERNAME,
            password: process.env.REACT_APP_SCAN_FILE_API_PASSWORD
        }),
    scanFile: (data, token) =>
        axios.post(url + '/Scan', data, { headers: { Authorization: `Bearer ${token}` } })
};
