import axios from 'axios';

const ENDPOINT = 'xero';

export const xeroApi = {
    exportInvoice: (data) => axios.post(ENDPOINT + '/invoice/multi', data),
    createInvoice: (id) => axios.post(ENDPOINT + '/invoice/' + id),
    connectXero: (params) => axios.get(ENDPOINT, { params }),
    tenants: (params) => axios.get(ENDPOINT + '/tenants', { params }),
    callbackXero: (data) => axios.post(ENDPOINT, data)
};
