import React, { useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Drawer,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { noteApi } from 'api/noteApi';
import DateFormatted from 'components/DateFormatted';
import MFile from 'components/MFile';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DATE_TIME_FORMAT } from 'constants/index';
import { cloneDeep } from 'lodash';
import MConfirmation from 'components/MConfirmation';
import NoteForm from './NoteForm';
import { fileApi } from 'api/fileApi';
import axios from 'axios';
import NoteList from './NoteList';

function Note() {
    const [show, setShow] = useState(false);
    const [list, setList] = useState([]);
    const [mapList, setMapList] = useState({});
    const [loadingList, setLoadingList] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [currentEditNote, setCurrentEditNote] = useState(null);
    const [isFormFocus, setIsFormFocus] = useState(false);

    useEffect(() => {
        void getList();
    }, []);

    const getList = async () => {
        try {
            setLoadingList(true);
            const {
                data: { rows }
            } = await noteApi.getList();

            setList(rows);

            const tempMap = {};
            rows.forEach((row) => {
                tempMap[row.id] = row;
                tempMap[row.id].loading = false;
            });
            setMapList(tempMap);

            setLoadingList(false);
        } catch (e) {
            setLoadingList(false);
        }
    };

    const toggle = () => setShow(!show);

    const handleDeleteNote = async (id) => {
        try {
            mapList[id].loading = true;
            setMapList(cloneDeep(mapList));
            await noteApi.delete(id);
            await getList();
        } catch (e) {
            mapList[id].loading = false;
            setMapList(cloneDeep(mapList));
        }
    };

    const onNoteSubmit = async (value, selectedFiles, deletedFiles, resetForm) => {
        let submitValue = { ...value };
        submitValue.files = [...deletedFiles];
        setLoadingForm(true);

        try {
            if (selectedFiles.length) {
                const { data: signedUrls } = await fileApi.getSignedUrlS3(
                    selectedFiles.map((selectedFile) => ({
                        filename: selectedFile.name,
                        content_type: selectedFile.type
                    }))
                );

                for (let i = 0; i < signedUrls.length; i++) {
                    await axios.put(signedUrls[i].url, selectedFiles[i], {
                        headers: {
                            'Content-Type': selectedFiles[i].type
                        }
                    });
                    submitValue.files.push({
                        name: selectedFiles[i].name,
                        mime_type: selectedFiles[i].type,
                        s3_path: signedUrls[i].filename
                    });
                }
            }

            if (!submitValue.id) delete submitValue.id;
            if (!submitValue.files.length) delete submitValue.files;

            submitValue.id
                ? await noteApi.update(submitValue.id, submitValue)
                : await noteApi.create(submitValue);
            resetForm && resetForm();
            setLoadingForm(false);
        } catch (e) {
            setLoadingForm(false);
        }
        await getList();
    };

    return (
        <>
            <Box
                position={'fixed'}
                right={20}
                bottom={20}
                zIndex={1000}>
                <Tooltip title={'Take Note'}>
                    <IconButton
                        color={'primary'}
                        onClick={toggle}
                        size={'large'}
                        sx={{ ml: 'auto' }}>
                        <NoteAltIcon fontSize={'large'} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Drawer
                anchor={'right'}
                open={show}
                onClose={toggle}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
                <Box
                    width={400}
                    position={'relative'}>
                    <Toolbar>
                        <Typography variant={'h6'}>Note Center</Typography>
                        <IconButton
                            onClick={toggle}
                            sx={{ ml: 'auto' }}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>

                    {loadingList && (
                        <LinearProgress
                            sx={{ position: 'absolute', left: 0, width: '100%', top: '4rem' }}
                        />
                    )}

                    <Box
                        px={2}
                        mb={2}>
                        <NoteForm
                            onSubmit={onNoteSubmit}
                            detail={currentEditNote}
                            onFocusChange={setIsFormFocus}
                            loading={loadingForm}
                            onCancel={() => setCurrentEditNote(null)}
                        />
                    </Box>

                    <NoteList
                        list={list}
                        mapList={mapList}
                        isFormFocus={isFormFocus}
                        onDeleteNote={handleDeleteNote}
                        onClickEditNote={setCurrentEditNote}
                    />
                </Box>
            </Drawer>
        </>
    );
}

export default Note;
