import axios from 'axios';

const ENDPOINT = 'process';

export const processApi = {
    get: (id) => axios.get(ENDPOINT + '/' + id),
    createOrder: (processId, payload) => axios.post(`${ENDPOINT}/${processId}/order`, payload),
    updateOrder: (processId, orderId, payload) =>
        axios.put(`${ENDPOINT}/${processId}/order/${orderId}`, payload),
    approve: (payload) => axios.post(`${ENDPOINT}/approve`, payload),
    terminate: (payload) => axios.post(`${ENDPOINT}/terminate`, payload),
    reject: (payload) => axios.post(`${ENDPOINT}/reject`, payload),
    putAdditionalData: (id, payload) => axios.put(`addition_process/${id}`, payload),
    updateCompany: (processId, companyId, payload) =>
        axios.put(`${ENDPOINT}/${processId}/company/${companyId}`, payload)
};
