import { createSlice } from '@reduxjs/toolkit';

const whatsAppSlice = createSlice({
    name: 'whatsapp',
    initialState: {
        socket: {}
    },
    reducers: {
        updateWhatsappWebsocket: (state, action) => {
            state.socket = action.payload.socket;
        }
    }
});

const { actions, reducer } = whatsAppSlice;

export const { updateWhatsappWebsocket } = actions;

export default reducer;
