/* Task */
export const TASK_READ = 'TASK_READ';
export const TASK_CREATE = 'TASK_CREATE';
export const TASK_EDIT = 'TASK_EDIT';
export const TASK_DELETE = 'TASK_DELETE';
export const TASK_RESTORE = 'TASK_RESTORE';

/* Order */
export const ORDER_READ = 'ORDER_READ';
export const ORDER_CREATE = 'ORDER_CREATE';
export const ORDER_EDIT = 'ORDER_EDIT';
export const ORDER_DELETE = 'ORDER_DELETE';
export const ORDER_RESTORE = 'ORDER_RESTORE';

/* Company */
export const COMPANY_READ = 'COMPANY_READ';
export const COMPANY_CREATE = 'COMPANY_CREATE';
export const COMPANY_EDIT = 'COMPANY_EDIT';
export const COMPANY_DELETE = 'COMPANY_DELETE';
export const COMPANY_FILE_READ = 'COMPANY_FILE_READ';
export const COMPANY_ASSOCIATE_FILE_READ = 'COMPANY_ASSOCIATE_FILE_READ';
export const COMPANY_ASSOCIATE_FILE_EDIT = 'COMPANY_ASSOCIATE_FILE_EDIT';
export const COMPANY_ASSOCIATE_FILE_DELETE = 'COMPANY_ASSOCIATE_FILE_DELETE';
export const COMPANY_ASSOCIATE_COMPANY_MEMBER_READ = 'COMPANY_ASSOCIATE_COMPANY_MEMBER_READ';
export const COMPANY_ASSOCIATE_COMPANY_MEMBER_EDIT = 'COMPANY_ASSOCIATE_COMPANY_MEMBER_EDIT';
export const COMPANY_ASSOCIATE_COMPANY_MEMBER_DELETE = 'COMPANY_ASSOCIATE_COMPANY_MEMBER_DELETE';
export const COMPANY_CALENDAR_READ = 'COMPANY_CALENDAR_READ';
export const COMPANY_CALENDAR_EDIT = 'COMPANY_CALENDAR_EDIT';
export const COMPANY_CALENDAR_DELETE = 'COMPANY_CALENDAR_DELETE';

/* Company member */
export const COMPANY_MEMBER_READ = 'COMPANY_MEMBER_READ';
export const COMPANY_MEMBER_EDIT = 'COMPANY_MEMBER_EDIT';
export const COMPANY_MEMBER_OWN = 'COMPANY_MEMBER_OWN';
export const COMPANY_MEMBER_DELETE = 'COMPANY_MEMBER_DELETE';

/* Customer*/
export const CUSTOMER_READ = 'CUSTOMER_READ';
export const CUSTOMER_CREATE = 'CUSTOMER_CREATE';
export const CUSTOMER_EDIT = 'CUSTOMER_EDIT';
export const CUSTOMER_DELETE = 'CUSTOMER_DELETE';
export const CUSTOMER_NOTIFY = 'CUSTOMER_NOTIFY';

/* Export */
export const COMPANY_EXPORT = 'COMPANY_EXPORT';
export const CUSTOMER_EXPORT = 'CUSTOMER_EXPORT';

/* Service */
export const SERVICE_READ = 'SERVICE_READ';
export const SERVICE_EDIT = 'SERVICE_EDIT';
export const SERVICE_DELETE = 'SERVICE_DELETE';

/* Package */
export const PACKAGE_READ = 'PACKAGE_READ';
export const PACKAGE_EDIT = 'PACKAGE_EDIT';
export const PACKAGE_DELETE = 'PACKAGE_DELETE';

/* Banking tool */
export const BANKING_TOOL_READ = 'BANKING_TOOL_READ';

/* USER */
export const USER_READ = 'USER_READ';
export const USER_EDIT = 'USER_EDIT';
export const USER_DELETE = 'USER_DELETE';
export const USER_CUSTOMER_READ = 'USER_CUSTOMER_READ';
export const USER_CUSTOMER_EDIT = 'USER_CUSTOMER_EDIT';
export const USER_CUSTOMER_DELETE = 'USER_CUSTOMER_DELETE';

/* Role */
export const ROLE_READ = 'ROLE_READ';
export const ROLE_EDIT = 'ROLE_EDIT';
export const ROLE_DELETE = 'ROLE_DELETE';

/* Agent */
export const AGENT_READ = 'AGENT_READ';
export const AGENT_EDIT = 'AGENT_EDIT';
export const AGENT_DELETE = 'AGENT_DELETE';

/* Config */
export const CONFIG_READ = 'CONFIG_READ';
export const CONFIG_EDIT = 'CONFIG_EDIT';
export const CONFIG_DELETE = 'CONFIG_DELETE';

/* Api */
export const API_READ = 'API_READ';
export const API_EDIT = 'API_EDIT';
export const API_DELETE = 'API_DELETE';

/* Syslog */
export const SYSLOG_READ = 'SYSLOG_READ';
export const SYSLOG_EDIT = 'SYSLOG_EDIT';
export const SYSLOG_DELETE = 'SYSLOG_DELETE';

/* Bank */
export const BANK_READ = 'BANK_READ';
export const BANK_EDIT = 'BANK_EDIT';
export const BANK_DELETE = 'BANK_DELETE';

/* Support case */
export const SUPPORT_CASE_READ = 'SUPPORT_CASE_READ';
export const SUPPORT_CASE_EDIT = 'SUPPORT_CASE_EDIT';
export const SUPPORT_CASE_DELETE = 'SUPPORT_CASE_DELETE';

/* Domain */
export const GLEADS_VIEW = 'GLEADS_VIEW';
export const VISA2ASIA_VIEW = 'VISA2ASIA_VIEW';
export const BBCSG_VIEW = 'BBCSG_VIEW';
export const ARRIVALHUB_VIEW = 'ARRIVALHUB_VIEW';
