import axios from 'axios';
const ENDPOINT = 'banking_process';
export const bankingProcessApi = {
    getBanking: (id) => axios.get(ENDPOINT + '/' + id),
    putScheduleMeeting: (id, payload) =>
        axios.put(ENDPOINT + '/' + id + '/schedule_meeting', payload),
    deleteProposedTime: (id) => axios.delete(ENDPOINT + '/proposed_time/' + id),
    putVideoCall: (payload) => axios.put(ENDPOINT + '/video_call', payload),
    putTrackingCode: (id, payload) => axios.put(ENDPOINT + '/' + id, payload),
    updateBusinessPlanForm: (taskId, payload) =>
        axios.put(ENDPOINT + '/business_plan_form/update_form/' + taskId, payload)
};
