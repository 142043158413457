import { BBCINCORP_ID } from 'constants';

export const PACKAGE_EDIT = {
    website_id: BBCINCORP_ID
    // name: '',
    // service_type_id: null,
    // country_id: null,
    // entity_type_id: null,
    // package_type_id: null,
    // package_fee: 0,
    // is_deleted: false,
    // created_time: null,
    // description: '',
    // Services: [],
    // AdditionServices: []
};
