import axios from 'axios';

const ENDPOINT = 'note';

export const noteApi = {
    getList: (params) =>
        axios.get(ENDPOINT, {
            params
        }),
    get: (id) => axios.get(ENDPOINT + '/' + id),
    getNotes: (params) =>
        axios.get(ENDPOINT + '/get_notes', {
            params
        }),
    delete: (id) => axios.delete(ENDPOINT + '/' + id),
    update: (id, data) => axios.put(ENDPOINT + '/cs/' + id, data),
    create: (data) => axios.post(ENDPOINT, data),
    createNotesRiskForm: (data) => axios.post('/risk_assessment_form/note', data),
    changeNoteStatus: (data) => axios.put(ENDPOINT + '/update_status', data),
};
