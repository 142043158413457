import { PRESIDENT_TYPE_ID } from 'constants';
import { TREASURER_TYPE_ID } from 'constants';
import { SECRETARY_TYPE_ID } from 'constants';
import { cloneDeep, isNil } from 'lodash';
import { EventEmitter } from 'events';

export function listToTree(list) {
    let map = {},
        node,
        roots = [];

    for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }

    for (let i = 0; i < list.length; i += 1) {
        node = list[i];
        // if you have dangling branches check that map[node.parent_id] exists
        if (node.parent_id && !isNil(map[node.parent_id])) {
            list[map[node.parent_id]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}

export function treeToList(tree) {
    return tree.reduce(function (acc, o) {
        acc.push(o);

        if (o.children.length) {
            acc = acc.concat(treeToList(o.children));
        }
        return acc;
    }, []);
}

export function getMergeCompanyShares(shares) {
    const uniqueIds = [];

    const unique = shares.filter((share) => {
        const isDuplicate = uniqueIds.includes(share.id);

        if (!isDuplicate) {
            uniqueIds.push(share.id);

            return true;
        }

        return false;
    });
    return unique;
}
const formatMembers = {
    getOnePositionMember: (members) => {
        if (!Array.isArray(members)) return [];
        const tempMembers = [];
        members.forEach((member) => {
            member.CompanyPositions.forEach((memberPosition) => {
                const newMember = { ...cloneDeep(member), CompanyPositions: [memberPosition] };
                if (
                    ![SECRETARY_TYPE_ID, PRESIDENT_TYPE_ID, TREASURER_TYPE_ID].includes(
                        memberPosition?.company_member_type_id
                    )
                ) {
                    tempMembers.push(newMember);
                }
            });
        });
        return tempMembers;
    },
    formatCompanyMembersHasOnePositionAndChildren: (members, parentId) => {
        if (!Array.isArray(members)) return [];
        const result = [];
        const membersTemp = cloneDeep(formatMembers.getOnePositionMember(members));

        // đệ quy trả về children của members
        for (let i in membersTemp) {
            const memberPositionsId = membersTemp[i].CompanyPositions.map(
                (position) => position?.corporation_company_member_id
            );
            memberPositionsId.forEach((positionId) => {
                if (positionId == parentId) {
                    const children = formatMembers.formatCompanyMembersHasOnePositionAndChildren(
                        membersTemp,
                        membersTemp[i].id
                    );
                    if (membersTemp[i].type_member_id === 2) {
                        // corporation
                        membersTemp[i].Children = children.length ? children : [];
                    }

                    result.push(membersTemp[i]);
                }
            });
        }

        return result;
    },
    formatCompanyMembersHasLevelOne: (members) => {
        if (!Array.isArray(members)) return [];
        const tempMembers = [];
        members.forEach((member) => {
            const membersPositionOne = member.CompanyPositions.filter(
                (position) => !position.corporation_company_member_id
            );
            if (membersPositionOne.length) {
                tempMembers.push({ ...cloneDeep(member), CompanyPositions: membersPositionOne });
            }
        });

        return tempMembers;
    }
};
export default formatMembers;

export const eventEmitter = new EventEmitter();