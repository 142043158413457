import { createAsyncThunk } from '@reduxjs/toolkit';
import { personalApi } from 'api/personalApi';

export const fetchPersonal = createAsyncThunk(
    'personal/fetchPersonal',
    async (payload, thunkAPI) => {
        try {
            const response = await personalApi.personal();
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);
