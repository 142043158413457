import React, { useState } from 'react';
import { Collapse, ListItem, ListItemIcon, ListItemText, List } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { MMenuItem } from './MMenuItem';

export const MultiLevel = ({ item, setOpenParent, isCloseCollapse }) => {
    const { items: children } = item;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    return (
        <div className="nav-root">
            <ListItem
                button
                onClick={handleClick}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{
                        style: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: 'rgba(255, 255, 255, 0.7)'
                        }
                    }}
                    primary={item.title}
                />
                {open ? (
                    <ExpandLess style={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                ) : (
                    <ExpandMore style={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                )}
            </ListItem>
            <Collapse
                in={isCloseCollapse ? false : open}
                timeout="auto">
                <List
                    component="div"
                    disablePadding
                    sx={{ paddingX: 0 }}>
                    {children.map((child, key) => (
                        <MMenuItem
                            key={key}
                            item={child}
                            setOpenParent={() => setOpen(true)}
                        />
                    ))}
                </List>
            </Collapse>
        </div>
    );
};
