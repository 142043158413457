import { CircularProgress, Box } from '@mui/material';
import React from 'react';

export function Loading() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 64px)'
            }}>
            <CircularProgress size={'60px'} />
        </Box>
    );
}
