import axios from 'axios';
const ENDPOINT = 'onboarding';
export const onboardingApi = {
    getBankings: (params) => {
        return axios.get(ENDPOINT + '/banking', { params });
    },
    getCountries: () => {
        return axios.get(ENDPOINT + '/country');
    }
};
