export const HONG_KONG_ID = 98;
export const PANAMA_ID = 169;
export const SINGAPORE_ID = 191;
export const JERSEY_ID = 111;
export const CYPRUS_ID = 58;
export const VIET_NAM_ID = 244;
export const CAYMAN_ID = 41;
export const MAURITIUS_ID = 139;
export const DELAWARE_ID = 250;
export const RAK_UAE_ID = 236;
export const UK_ID = 237;
export const BELIZE_ID = 23;
export const ST_KIT = 205;
export const BAHAMAS_ID = 17;
export const MARSHALL_ID = 136;
export const BVI_ID = 32;
export const SVG_ID = 209;
export const SEYCHELLES_ID = 189;
