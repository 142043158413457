import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { ListItem } from '@mui/material';
import { ListItemIcon, ListItemText } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const SingleLevel = ({ item, setOpenParent }) => {
    let firstLoad = useRef(true);

    return (
        <NavLink
            to={item.to}
            className={({ isActive }) => {
                if (isActive) {
                    if (firstLoad.current) {
                        setTimeout(() => {
                            setOpenParent && setOpenParent();
                        }, 50);
                        firstLoad.current = false;
                    }
                    return 'nav-active';
                }
                return 'nav';
            }}>
            <ListItem
                button
                sx={{ ...item?.style }}>
                <ListItemIcon>
                    {item.icon ? item.icon : <FiberManualRecordIcon sx={{ fontSize: 12 }} />}
                </ListItemIcon>
                <ListItemText
                    primary={item.title}
                    primaryTypographyProps={{
                        style: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }
                    }}
                />
            </ListItem>
        </NavLink>
    );
};
