import React from 'react';
import {
    Box,
    Card,
    CardContent,
    IconButton,
    List,
    ListItem,
    Tooltip,
    Typography
} from '@mui/material';
import DateFormatted from 'components/DateFormatted';
import { DATE_TIME_FORMAT } from 'constants/index';
import MFile from 'components/MFile';
import EditIcon from '@mui/icons-material/Edit';
import MConfirmation from 'components/MConfirmation';
import DeleteIcon from '@mui/icons-material/Delete';
import { getFullName } from 'helper';
import BlockWrapper from 'components/BlockWrapper';

function NoteList({
    list,
    mapList,
    isFormFocus,
    listItemProps = {},
    notFoundProps = {},
    disabled = false,
    readOnly = false,
    onClickEditNote,
    onDeleteNote,
    hiddenEditBtn = false,
    hiddenTitle = false
}) {
    return (
        <List
            sx={{
                maxHeight: isFormFocus ? 'calc(100vh - 328px)' : 'calc(100vh - 152px)',
                overflowY: 'auto',
                paddingTop: 0
            }}>
            {!list?.length && (
                <Typography
                    p={2}
                    fontSize={'0.9rem'}
                    {...notFoundProps}>
                    Not found note
                </Typography>
            )}
            {list.map((note) => (
                <ListItem
                    key={note.id}
                    className={'group'}
                    {...listItemProps}>
                    <BlockWrapper sx={{ width: '100%' }}>
                        <p className={'text-xs text-slate-400 mb-1'}>
                            {note?.User?.Person && (
                                <strong>
                                    {getFullName(note?.User?.Person, true)}{' '}
                                    {note?.User?.Roles?.length > 0 && (
                                        <span>
                                            {' - '}(
                                            {note?.User?.Roles?.map((role) => role.name)?.join(
                                                ', '
                                            )}
                                            )
                                        </span>
                                    )}
                                    {' - '}
                                </strong>
                            )}
                            <DateFormatted
                                date={note.created_time}
                                format={DATE_TIME_FORMAT}
                            />
                        </p>

                        {!hiddenTitle && (
                            <p className={'whitespace-pre font-bold mb-2'}>{note.title}</p>
                        )}
                        <p className={'text-sm'}>{note.description}</p>

                        <Box>
                            {note?.Files?.map((file) => (
                                <MFile
                                    file={file}
                                    showWithLabel={true}
                                    key={file.id}
                                    Icon={''}
                                    labelClasses={'underline'}
                                />
                            ))}
                        </Box>

                        {!readOnly && (
                            <Box
                                display={'flex'}
                                className={
                                    'opacity-0 group-hover:opacity-100 ease-in-out duration-300'
                                }
                                justifyContent={'flex-end'}>
                                {!hiddenEditBtn && (
                                    <Tooltip title={'Edit Note'}>
                                        <IconButton
                                            disabled={disabled}
                                            onClick={() => onClickEditNote(note)}
                                            aria-label="edit-note"
                                            size="small">
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <MConfirmation
                                    title={'Delete note'}
                                    content={'Do you want to delete note?'}
                                    disabled={disabled}
                                    onApprove={() => onDeleteNote(note.id)}>
                                    <Tooltip title={'Delete Note'}>
                                        <IconButton
                                            color={'error'}
                                            aria-label="delete-note"
                                            disabled={!!mapList[note.id]?.loading || disabled}
                                            size="small">
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </MConfirmation>
                            </Box>
                        )}
                    </BlockWrapper>
                </ListItem>
            ))}
        </List>
    );
}

export default NoteList;
