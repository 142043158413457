import { useMemo } from 'react';
import { useQueryParams } from './useQueryParams';
import { cloneDeep } from 'lodash';

/**
 * Convert data according by url
 * @param {*} defaultValue data
 * @param {*} keyParse key want to parse to array or number
 * @returns
 */
export const useMakeFormDefault = (defaultValue, keyParse = []) => {
    const newDefault = cloneDeep(defaultValue);
    const params = useQueryParams();
    const keyNeedParse = useMemo(() => {
        if (keyParse)
            return keyParse.reduce((obj, item) => Object.assign(obj, { [item]: item }), {});
    }, keyParse);

    const returnValue = (func, value) => {
        try {
            return func();
        } catch (error) {
            return value;
        }
    };

    for (const key in newDefault) {
        newDefault[key] =
            (keyNeedParse[key]
                ? returnValue(() => JSON.parse(params.get(key)), newDefault[key])
                : params.get(key)) || newDefault[key];
    }

    return { newDefault };
};
