import { createTheme } from '@mui/material';

export const myTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    textTransform: 'none!important' // Override CSS transform uppercase button label of mui!!!
                }
            }
        },
        MuiDialog: {
            defaultProps: {
                fullWidth: true,
                scroll: 'paper'
            }
        },
        MuiDialogContent: {
            defaultProps: {
                dividers: true
            }
        },
        MuiGrid: {
            defaultProps: {
                spacing: 2
            }
        }
    },
    typography: {
        fontFamily: `"Roboto", "Helvetica Neue", sans- serif`,
        fontSize: 13,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700
    },
    palette: {
        primary: {
            main: '#3783e7'
        },
        // secondary: {
        //     main: '#ff9e43'
        // },
        error: {
            main: '#ff0000'
        },
        disable: {
            main: '#9e9e9e'
        },
        green500: {
            main: '#22c55e'
        },
        success: {
            main: '#388e3c'
        }
    }
});
