import { ARRIVALHUB_ID } from "constants";

export const SERVICE_EDIT = {
    website_id: ARRIVALHUB_ID,
    // name: '',
    // service_type_id: null,
    // description: '',
    // xero_item_code: '',
    // xero_item_id: '',
    // is_displayed: false,
    // is_attach_incorp: false,
    // is_service_outside_company: false,
    // service_unit_id: null,
    // Countries: [],
    // Fee: {
    //     value: '',
    //     fee_type_id: null
    // }
};
