import axios from 'axios';

const ENDPOINT = 'api';

export const configApi = {
    // Api
    getListApi: (params) => axios.get(ENDPOINT, { params }),
    getApi: (id) => axios.get(ENDPOINT + '/' + id),
    getApiField: (id) => axios.get(ENDPOINT + '/' + id + '/field'),
    deleteApi: (id) => axios.delete(ENDPOINT + '/' + id),
    updateApi: (id, payload) => axios.put(ENDPOINT + '/' + id, payload),
    createApi: (payload) => axios.post(ENDPOINT, payload),
    removeApiGroup: (payloads) =>
        axios.delete(ENDPOINT + '/api_group/delete_multi', {
            params: {
                ids: JSON.stringify(payloads)
            }
        }),
    removeApiExample: (payloads) =>
        axios.delete(ENDPOINT + '/example/delete_multi', {
            params: {
                ids: JSON.stringify(payloads)
            }
        }),
    getFieldApi: (params) => axios.get(ENDPOINT + '/research', { params }),

    // Group
    getGroup: (id) => axios.get(ENDPOINT + '/group/' + id),
    addGroup: (payload) => axios.post(ENDPOINT + '/group', payload),
    updateGroup: (id, payload) => axios.put(ENDPOINT + '/group/' + id, payload),
    deleteGroup: (id) => axios.delete(ENDPOINT + '/group/' + id)
};
