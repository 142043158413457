export const MONTH_OPTIONS = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' }
];

export const TYPE_MEMBER_INDIVIDUAL = 1;
export const TYPE_MEMBER_CORPORATION = 2;

export const TYPE_MEMBER_OPTIONS = [
    { id: TYPE_MEMBER_INDIVIDUAL, name: 'Individual' },
    { id: TYPE_MEMBER_CORPORATION, name: 'Corporation' }
];

export const TYPE_MEMBER_OPTIONS_UBO = [{ id: TYPE_MEMBER_INDIVIDUAL, name: 'Individual' }];
