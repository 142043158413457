import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Alert, AlertTitle } from '@mui/lab';
import { NavLink } from 'react-router-dom';

function ErrorBoundaryFallback({ error }) {
    if (error?.name === 'ChunkLoadError') {
        return (
            <Box p={4}>
                <Alert severity="info">
                    <AlertTitle>Notification</AlertTitle>
                    <Typography>
                        New version has been released, please{' '}
                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={() => window.location.reload()}>
                            Reload
                        </Button>{' '}
                        app.
                    </Typography>
                </Alert>
            </Box>
        );
    }
    if (process.env.REACT_APP_PRODUCTION === 'true') {
        console.log(error);
        return (
            <Box
                p={4}
                role="alert">
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    <Typography>
                        Something went wrong!{' '}
                        <a href={'/'}>
                            <Button variant={'outlined'}>Go to homepage</Button>
                        </a>
                    </Typography>
                </Alert>
            </Box>
        );
    }
    return (
        <Box
            p={4}
            role="alert">
            <p>An error occurred:</p>
            <pre>{error.message}</pre>
        </Box>
    );
}

export default ErrorBoundaryFallback;
