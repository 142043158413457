import React from 'react';
import { Box } from '@mui/material';

function BlockWrapper({ children, ...props }) {
    return (
        <Box
            className={'border border-gray-200'} // bg-sky-50/50
            borderRadius={1}
            p={2}
            mb={2}
            {...props}>
            {children}
        </Box>
    );
}

export default BlockWrapper;
