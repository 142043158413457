import axios from 'axios';

const ENDPOINT = 'order';

export const orderApi = {
    getList: (params) =>
        axios.get(ENDPOINT, {
            params
        }),
    getListLazy: (params) =>
        axios.get(ENDPOINT + '/lazy', {
            params
        }),
    getListAll: (params) =>
        axios.get(ENDPOINT + '/all', {
            params
        }),
    create: (data) => axios.post(ENDPOINT, data),
    get: (id) => axios.get(ENDPOINT + '/' + id),
    restore: (id) => axios.put(ENDPOINT + '/restore/' + id),
    update: (id, data) => axios.put(ENDPOINT + '/' + id, data),
    delete: (id) => axios.delete(ENDPOINT + '/' + id),
    export: (id) => axios.delete(ENDPOINT + '/' + id),
    updateOrderCompany: (id, data) => axios.put(ENDPOINT + '/order_company/' + id, data),
    updateId: (id, data) => axios.put(ENDPOINT + '/id/' + id, data),
    getOrder: (id) => axios.get(ENDPOINT + '/invoice/' + id),
    getYearsList: (id) => axios.get(ENDPOINT + '/' + id + '/years_not_buy_event'),
    saveYearsList: (id, data) => axios.post(ENDPOINT + '/' + id + '/company_event', data)
};
