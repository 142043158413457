import axios from 'axios';

const ENDPOINT = 'auth';

export const authApi = {
    login: function login(payload) {
        axios.defaults.headers.post['captcha-response'] = payload.token
        return axios.post(ENDPOINT, payload.values);
    },
    loginV2: function login(access_token) {
        return axios.post(`${ENDPOINT}/social_login`, { access_token });
    },
    refreshToken: function refreshToken(payload) {
        return axios.post(`${ENDPOINT}/refresh_token`, payload);
    },
    getAccessTokenDetail(access_token) {
        return axios.get(`${ENDPOINT}/passport`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });
    },
    saveAuthData: function ({
        access_token,
        access_token_exp,
        refresh_token,
        refresh_token_exp,
        Roles,
        Permissions,
        Limits
    }) {
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('access_token_exp', access_token_exp);
        localStorage.setItem('refresh_token', refresh_token);
        localStorage.setItem('refresh_token_exp', refresh_token_exp);
        localStorage.setItem('Roles', JSON.stringify(Roles));
        localStorage.setItem('Permissions', JSON.stringify(Permissions));
        localStorage.setItem('Limits', JSON.stringify(Limits));
    },
    clearAuthData: function () {
        localStorage.removeItem('access_token');
        localStorage.removeItem('access_token_exp');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('refresh_token_exp');
        localStorage.removeItem('Roles');
        localStorage.removeItem('Permissions');
        localStorage.removeItem('Limits');
    }
};
