import axios from 'axios';

const ENDPOINT = 'user';

export const userApi = {
    getList: (params) =>
        axios.get(ENDPOINT, {
            params
        }),
    get: (id) => axios.get(ENDPOINT + '/' + id),
    delete: (id) => axios.delete(ENDPOINT + '/' + id),
    update: (id, data) => axios.put(ENDPOINT + '/' + id, data),
    create: (data) => axios.post(ENDPOINT, data),
    getRole: (id) => axios.get(ENDPOINT + '/' + id + '/role'),
    updateRole: (id, data) => axios.put(ENDPOINT + '/' + id + '/role', data)
};
