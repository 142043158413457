import axios from 'axios';

const ENDPOINT = 'website';

export const websiteApi = {
    getList: (params) =>
        axios.get(ENDPOINT, {
            params
        }),
    get: (id) => axios.get(ENDPOINT + '/' + id),
    delete: (id) => axios.delete(ENDPOINT + '/' + id),
    update: (id, data) => axios.put(ENDPOINT + '/' + id, data),
    create: (data) => axios.post(ENDPOINT, data),
    mailVerifyUrl: (id) => axios.get(`${ENDPOINT}/mail_verify_url/${id}`),
    setMailToken: (id) => axios.put(`${ENDPOINT}/mail_token/${id}`),
    navigateAuth2: (id) => axios.get(`${ENDPOINT}/companies_house/auth`)
};
