import axios from 'axios';

const ENDPOINT = 'statistic';

export const statisticApi = {
    // bod
    getAdvertising: (filter) => axios.get(ENDPOINT + '/bod/cost', { params: filter }),
    getOrderPaid: (filter) => axios.get(ENDPOINT + '/bod/order_paid', { params: filter }),
    getNewContact: (filter) => axios.get(ENDPOINT + '/bod/new_contact', { params: filter }),
    getByBod12Month: (filter) => axios.get(ENDPOINT + '/bod/12month', { params: filter }),
    getRatioEachServiceMonth: (filter) =>
        axios.get(ENDPOINT + '/bod/ratio_each_service_month', { params: filter }),
    getRatioEachServiceCountry: (filter) =>
        axios.get(ENDPOINT + '/bod/ratio_each_service_country', { params: filter }),
    getCustomerPaid12Month: (filter) =>
        axios.get(ENDPOINT + '/bod/customer_paid_12_month', { params: filter }),

    // cs
    getTopRank: (filter) =>
        axios.get(ENDPOINT + '/cs/top_rank_cs', {
            params: {
                ...filter,
                order_by: 'total_amount',
                order_sort: 'desc',
                limit: 5,
                offset: 0
            }
        }),
    getTargetTeam: (filter) => axios.get(ENDPOINT + '/cs/target_team', { params: filter }),
    getYourPerformance: (filter) =>
        axios.get(ENDPOINT + '/cs/your_performance', { params: filter }),
    getPerformanceOvertime: (filter) =>
        axios.get(ENDPOINT + '/cs/performance_overtime', { params: filter })
};
