/* Additional service */
export const MAIL_SERVICE_REQUEST_AGENT_CERTIFICATION = 'MAIL_SERVICE_REQUEST_AGENT_CERTIFICATION';
export const MAIL_NOTIFY_CLIENT_ABOUT_THE_RESULT = 'MAIL_NOTIFY_CLIENT_ABOUT_THE_RESULT';
export const MAIL_SERVICE_REQUEST_AGENT_SHIPPING = 'MAIL_SERVICE_REQUEST_AGENT_SHIPPING';
export const MAIL_SERVICE_REQUEST_AGENT_ROUND_CHOP = 'MAIL_SERVICE_REQUEST_AGENT_ROUND_CHOP';
export const MAIL_CHECK_COMPANY_NAME_CLIENT = 'MAIL_CHECK_COMPANY_NAME_CLIENT';
export const MAIL_CHECK_COMPANY_NAME_AGENT = 'MAIL_CHECK_COMPANY_NAME_AGENT';
export const MAIL_REVIEW_EMAIL_DOCUMENT_CUSTOM = 'MAIL_REVIEW_EMAIL_DOCUMENT_CUSTOM';
export const MAIL_REVIEW_SEND_ORDER_FORM_AGENT = 'MAIL_REVIEW_SEND_ORDER_FORM_AGENT';
export const MAIL_REVIEW_SEND_DOCUMENT_AGENT = 'MAIL_REVIEW_SEND_DOCUMENT_AGENT';
export const MAIL_BUSINESS_PLAN_FORM_TO_CLIENT = 'mail_business_plan_form_to_client';
export const MAIL_DIFFERENT_TIME_MEETING_TO_CLIENT = 'MAIL_DIFFERENT_TIME_MEETING_TO_CLIENT';
export const MAIL_SCHEDULE_MEETING_NOTIFY_BANK = 'MAIL_SCHEDULE_MEETING_NOTIFY_BANK';
export const MAIL_PROPOSED_TIME_MEETING_TO_CLIENT = 'MAIL_PROPOSED_TIME_MEETING_TO_CLIENT';
export const MAIL_APPLICATION_SET_TO_CLIENT = 'MAIL_APPLICATION_SET_TO_CLIENT';
export const MAIL_APPLICATION_SET_TO_AGENT = 'MAIL_APPLICATION_SET_TO_AGENT';
export const MAIL_TRACKING_CODE_TO_THE_BANK_REVIEW_APP_SET =
    'MAIL_TRACKING_CODE_TO_THE_BANK_REVIEW_APP_SET';
export const MAIL_TRACKING_CODE_TO_THE_BANK = 'MAIL_TRACKING_CODE_TO_THE_BANK';
export const MAIL_BUSINESS_PLAN_FORM_TO_AGENT = 'MAIL_BUSINESS_PLAN_FORM_TO_AGENT';
export const MAIL_DEPOSIT_TO_CLIENT = 'MAIL_DEPOSIT_TO_CLIENT';
export const MAIL_DEPOSIT_TO_AGENT = 'MAIL_DEPOSIT_TO_AGENT';
export const MAIL_APPLICATION_FORM_TO_CLIENT = 'MAIL_APPLICATION_FORM_TO_CLIENT';
export const MAIL_APPLICATION_FORM_TO_AGENT = 'MAIL_APPLICATION_FORM_TO_AGENT';
export const MAIL_WET_SIGNING_ORDER_FORM_CUSTOMER = 'mail_wet_signing_order_form_customer';
export const MAIL_WET_SIGNING_DOCUMENT_CUSTOMER = 'mail_wet_signing_document_customer';
export const MAIL_WET_SIGNING_SUPPLEMENT_CDC = 'mail_wet_signing_supplement_cdc';
export const MAIL_REMINDER_TO_CLIENT = 'mail_reminder_to_client';

export const MAIL_CLIENT_REQUEST_CSMEMBER_CHECK_SEND_AGENT =
    'MAIL_CLIENT_REQUEST_CSMEMBER_CHECK_SEND_AGENT';
export const MAIL_CLIENT_REQUEST_CSMEMBER_CHECK_SEND_CLIENT =
    'MAIL_CLIENT_REQUEST_CSMEMBER_CHECK_SEND_CLIENT';
export const MAIL_SERVICE_REQUEST_AGENT_CHANGE_OFFICER =
    'mail_service_request_agent_change_officer';
export const MAIL_SERVICE_REQUEST_AGENT_CHANGE_COMPANY =
    'mail_service_request_agent_change_company';
export const MAIL_SERVICE_REQUEST_AGENT_CHANGE_NAME_OFFICER =
    'mail_service_request_agent_change_name_officer';
export const MAIL_DOC_SIGNING_CLIENT_CHANGE_NAME = 'mail_doc_signing_client_change_name';
export const MAIL_DOC_SIGNING_CLIENT_CHANGE_OFFICER = 'mail_doc_signing_client_change_officer';
export const MAIL_DOC_SIGNING_CLIENT_CHANGE_NAME_OFFICER =
    'mail_doc_signing_client_change_name_officer';
export const MAIL_SEND_DOC_SIGNED_TO_AGENT_CHANGE_NAME =
    'mail_send_doc_signed_to_agent_change_name';
export const MAIL_SEND_DOC_SIGNED_TO_AGENT_CHANGE_OFFICER =
    'mail_send_doc_signed_to_agent_change_officer';
export const MAIL_SEND_DOC_SIGNED_TO_AGENT_CHANGE_NAME_OFFICER =
    'mail_send_doc_signed_to_agent_change_name_officer';
export const MAIL_REQUEST_AGENT_BOOKKEEPER = 'mail_request_agent_bookkeeper';
export const MAIL_REQUEST_CLIENT_SUPPLEMENT_DOCS = 'mail_request_client_supplement_docs';

export const PREVIEW_MAIL_MAP = {
    [MAIL_SERVICE_REQUEST_AGENT_CERTIFICATION]: {
        URL_GET: 'task/mail_service_request_agent_certification',
        URL_POST: 'task/mail_service_request_agent_certification'
    },
    [MAIL_NOTIFY_CLIENT_ABOUT_THE_RESULT]: {
        URL_GET: 'task/mail_notify_client_about_the_result',
        URL_POST: 'task/mail_notify_client_about_the_result'
    },

    [MAIL_SERVICE_REQUEST_AGENT_SHIPPING]: {
        URL_GET: 'task/mail_service_request_agent_shipping',
        URL_POST: 'task/mail_service_request_agent_shipping'
    },
    [MAIL_SERVICE_REQUEST_AGENT_ROUND_CHOP]: {
        URL_GET: 'task/mail_service_request_agent_round_chop',
        URL_POST: 'task/mail_service_request_agent_round_chop'
    },
    [MAIL_CHECK_COMPANY_NAME_CLIENT]: {
        URL_GET: 'task/review_email_checkname_customer',
        URL_POST: 'task/send_email_checkname_customer'
    },
    [MAIL_CHECK_COMPANY_NAME_AGENT]: {
        URL_GET: 'task/review_email_checkname_agent',
        URL_POST: 'task/send_name_to_agent'
    },
    [MAIL_REVIEW_EMAIL_DOCUMENT_CUSTOM]: {
        URL_GET: 'task/review_email_document_custom',
        URL_POST: 'task/send_email_document_custom'
    },
    [MAIL_REVIEW_SEND_ORDER_FORM_AGENT]: {
        URL_GET: 'task/review_send_order_form_agent',
        URL_POST: 'task/send_order_form_agent'
    },
    [MAIL_REVIEW_SEND_DOCUMENT_AGENT]: {
        URL_GET: 'task/review_send_document_agent',
        URL_POST: 'task/send_document_agent'
    },
    [MAIL_BUSINESS_PLAN_FORM_TO_CLIENT]: {
        URL_GET: 'task/mail_business_plan_form_to_client',
        URL_POST: 'task/mail_business_plan_form_to_client'
    },
    [MAIL_BUSINESS_PLAN_FORM_TO_AGENT]: {
        URL_GET: 'task/mail_business_plan_form_to_agent',
        URL_POST: 'task/mail_business_plan_form_to_agent'
    },
    [MAIL_DIFFERENT_TIME_MEETING_TO_CLIENT]: {
        URL_GET: 'task/mail_different_time_meeting_to_client',
        URL_POST: 'task/mail_different_time_meeting_to_client'
    },
    [MAIL_SCHEDULE_MEETING_NOTIFY_BANK]: {
        URL_GET: 'task/mail_schedule_meeting_notify_bank',
        URL_POST: 'task/mail_schedule_meeting_notify_bank'
    },
    [MAIL_PROPOSED_TIME_MEETING_TO_CLIENT]: {
        URL_GET: 'task/mail_proposed_time_meeting_to_client',
        URL_POST: 'task/mail_proposed_time_meeting_to_client'
    },
    [MAIL_APPLICATION_SET_TO_CLIENT]: {
        URL_GET: 'task/mail_application_set_to_client',
        URL_POST: 'task/mail_application_set_to_client'
    },
    [MAIL_APPLICATION_SET_TO_AGENT]: {
        URL_GET: 'task/mail_application_set_to_agent',
        URL_POST: 'task/mail_application_set_to_agent'
    },
    [MAIL_TRACKING_CODE_TO_THE_BANK_REVIEW_APP_SET]: {
        URL_GET: 'task/mail_tracking_code_to_the_bank_review_app_set',
        URL_POST: 'task/mail_tracking_code_to_the_bank_review_app_set'
    },
    [MAIL_TRACKING_CODE_TO_THE_BANK]: {
        URL_GET: 'task/mail_tracking_code_to_the_bank',
        URL_POST: 'task/mail_tracking_code_to_the_bank'
    },
    [MAIL_DEPOSIT_TO_CLIENT]: {
        URL_GET: 'task/mail_deposit_to_client',
        URL_POST: 'task/mail_deposit_to_client'
    },
    [MAIL_DEPOSIT_TO_AGENT]: {
        URL_GET: 'task/mail_deposit_to_agent',
        URL_POST: 'task/mail_deposit_to_agent'
    },
    [MAIL_APPLICATION_FORM_TO_CLIENT]: {
        URL_GET: 'task/mail_application_form_to_client',
        URL_POST: 'task/mail_application_form_to_client'
    },
    [MAIL_APPLICATION_FORM_TO_AGENT]: {
        URL_GET: 'task/mail_application_form_to_agent',
        URL_POST: 'task/mail_application_form_to_agent'
    },
    [MAIL_CLIENT_REQUEST_CSMEMBER_CHECK_SEND_AGENT]: {
        URL_GET: 'task/mail_client_request_csmember_check_send_agent',
        URL_POST: 'task/mail_client_request_csmember_check_send_agent'
    },
    [MAIL_CLIENT_REQUEST_CSMEMBER_CHECK_SEND_CLIENT]: {
        URL_GET: 'task/mail_client_request_csmember_check_send_client',
        URL_POST: 'task/mail_client_request_csmember_check_send_client'
    },
    [MAIL_SERVICE_REQUEST_AGENT_CHANGE_OFFICER]: {
        URL_GET: 'task/mail_service_request_agent_change_officer',
        URL_POST: 'task/mail_service_request_agent_change_officer'
    },
    [MAIL_SERVICE_REQUEST_AGENT_CHANGE_COMPANY]: {
        URL_GET: 'task/mail_service_request_agent_change_company',
        URL_POST: 'task/mail_service_request_agent_change_company'
    },
    [MAIL_SERVICE_REQUEST_AGENT_CHANGE_NAME_OFFICER]: {
        URL_GET: 'task/mail_service_request_agent_change_name_officer',
        URL_POST: 'task/mail_service_request_agent_change_name_officer'
    },
    [MAIL_DOC_SIGNING_CLIENT_CHANGE_NAME]: {
        URL_GET: 'task/mail_doc_signing_client_change_name',
        URL_POST: 'task/mail_doc_signing_client_change_name'
    },
    [MAIL_DOC_SIGNING_CLIENT_CHANGE_OFFICER]: {
        URL_GET: 'task/mail_doc_signing_client_change_officer',
        URL_POST: 'task/mail_doc_signing_client_change_officer'
    },
    [MAIL_DOC_SIGNING_CLIENT_CHANGE_NAME_OFFICER]: {
        URL_GET: 'task/mail_doc_signing_client_change_name_officer',
        URL_POST: 'task/mail_doc_signing_client_change_name_officer'
    },
    [MAIL_SEND_DOC_SIGNED_TO_AGENT_CHANGE_NAME]: {
        URL_GET: 'task/mail_send_doc_signed_to_agent_change_name',
        URL_POST: 'task/mail_send_doc_signed_to_agent_change_name'
    },
    [MAIL_SEND_DOC_SIGNED_TO_AGENT_CHANGE_OFFICER]: {
        URL_GET: 'task/mail_send_doc_signed_to_agent_change_officer',
        URL_POST: 'task/mail_send_doc_signed_to_agent_change_officer'
    },
    [MAIL_SEND_DOC_SIGNED_TO_AGENT_CHANGE_NAME_OFFICER]: {
        URL_GET: 'task/mail_send_doc_signed_to_agent_change_name_officer',
        URL_POST: 'task/mail_send_doc_signed_to_agent_change_name_officer'
    },
    [MAIL_WET_SIGNING_ORDER_FORM_CUSTOMER]: {
        URL_GET: 'task/mail_wet_signing_order_form_customer',
        URL_POST: 'task/mail_wet_signing_order_form_customer'
    },
    [MAIL_WET_SIGNING_DOCUMENT_CUSTOMER]: {
        URL_GET: 'task/mail_wet_signing_document_customer',
        URL_POST: 'task/mail_wet_signing_document_customer'
    },
    [MAIL_WET_SIGNING_SUPPLEMENT_CDC]: {
        URL_GET: 'task/review_email_supplement_document',
        URL_POST: 'task/send_email_supplement_document'
    },
    [MAIL_REMINDER_TO_CLIENT]: {
        URL_GET: 'task/review_email',
        URL_POST: 'task/send_email'
    },
    [MAIL_REQUEST_AGENT_BOOKKEEPER]: {
        URL_GET: 'task/mail_request_agent_bookkeeper',
        URL_POST: 'task/mail_request_agent_bookkeeper'
    },
    [MAIL_REQUEST_CLIENT_SUPPLEMENT_DOCS]: {
        URL_GET: 'task/mail_request_client_supplement_docs',
        URL_POST: 'task/mail_request_client_supplement_docs'
    },
};
