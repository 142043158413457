import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { clearAuthData, updateHasNoAdminLayout } from './features/auth/store/authSlice';
import { updatePersonal } from './features/personal/store/personalSlice';
import { ErrorBoundary } from 'react-error-boundary';
import { myTheme } from './AppTheme';
import AppLoading from './app/components/AppLoading';
import ErrorBoundaryFallback from './app/components/ErrorBoundaryFallback';
import AppRouting from './AppRouting';
import { GoogleOAuthProvider } from '@react-oauth/google';
import TokenExpired from 'app/components/TokenExpired';
import { render } from 'react-dom';

function App() {
    const location = useLocation();
    const access_token = useSelector((state) => state.auth.access_token);
    const { access_token_exp } = useSelector((state) => state.auth);
    let [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /* Effect này chỉ xử lý khi có access_token
     * Không gắn logic xử lý khác! */
    useLayoutEffect(() => {
        /* Cần check có access_token và access token khác với access_token */
        if (
            searchParams.get('access_token') &&
            access_token &&
            searchParams.get('access_token') !== access_token
        ) {
            dispatch(clearAuthData());
            dispatch(updatePersonal(null));
            navigate('/auth/login?access_token=' + searchParams.get('access_token'));
        }
    }, []);

    /* Effect này chỉ xử lý khi có no_admin_layout
     * Không gắn logic xử lý khác! */
    useLayoutEffect(() => {
        if (searchParams.get('no_admin_layout')) {
            dispatch(updateHasNoAdminLayout(true));
        }
    }, []);
    useEffect(() => {
        if(access_token && access_token_exp * 1000 > new Date().getTime() && location?.pathname !== "/auth/login") {
            let time = access_token_exp * 1000 - new Date().getTime()
            const timeout = setTimeout(() => {
                render(<TokenExpired open={true}/>, document.getElementById("token-expired"));
            }, time);
            return () => {
                timeout && clearTimeout(timeout);
            };
        }
    }, [access_token]);
    return (
        <ErrorBoundary FallbackComponent={({ error }) => <ErrorBoundaryFallback error={error} />}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <ThemeProvider theme={myTheme}>
                    <div className="App">
                        <Suspense fallback={<AppLoading />}>
                            <AppRouting />
                            <ToastContainer
                                theme="colored"
                                position="bottom-left"
                                hideProgressBar
                                autoClose={3000}
                            />
                            <div id="token-expired" />
                        </Suspense>
                    </div>
                </ThemeProvider>
            </GoogleOAuthProvider>
        </ErrorBoundary>
    );
}

export default App;
