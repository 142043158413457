import { createSlice } from '@reduxjs/toolkit';
import {
    fetchNewsNotification,
    fetchOthersNotification,
    fetchMessageNotification,
    fetchCheckNotification,
    fetchReadAllNewsNotification,
    fetchReadAllMessageNotification,
    fetchReadAllOthersNotification,
    fetchWhatsappNotification
} from './notificationThunk';

const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        loadingNewsNotification: false,
        newsNotifications: [],
        pageNewsNotifications: 0,
        countUnreadNewsNotification: 0,
        unreadNewsNotification: [],
        unreadNewsNotificationMap: [],
        loadingReadAllNewsNotification: false,

        loadingMessageNotification: false,
        messageNotifications: [],
        pageMessageNotifications: 0,
        countUnreadMessageNotification: 0,
        unreadMessageNotification: [],
        unreadMessageNotificationMap: [],
        loadingReadAllMessageNotification: false,

        loadingOthersNotification: false,
        othersNotifications: [],
        pageOthersNotifications: 0,
        countUnreadOthersNotification: 0,
        unreadOthersNotification: [],
        unreadOthersNotificationMap: [],
        loadingReadAllOthersNotification: false,

        listUnreadTask: {},

        countUnreadWhatsappNotification: 0
    },
    reducers: {
        updatePageNewsNotifications: (state, action) => {
            state.pageNewsNotifications = action.payload;
        },
        updatePageMessageNotifications: (state, action) => {
            state.pageMessageNotifications = action.payload;
        },
        updatePageOthersNotifications: (state, action) => {
            state.pageOthersNotifications = action.payload;
        },
        updateNewsNotifications: (state, action) => {
            state.newsNotifications = [action.payload].concat(state.newsNotifications);
        },
        updateMessageNotifications: (state, action) => {
            state.messageNotifications = [action.payload].concat(state.messageNotifications);
        },
        updateOthersNotifications: (state, action) => {
            state.othersNotifications = [action.payload].concat(state.othersNotifications);
        },
        clearNotification: (state) => {
            state.messageNotifications = state.othersNotifications = state.newsNotifications = [];
            state.pageMessageNotifications =
                state.pageOthersNotifications =
                state.pageNewsNotifications =
                    0;
        },
        updateWhatsappNotification: (state, action) => {
            state.countUnreadWhatsappNotification = action.payload.countUnread;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNewsNotification.fulfilled, (state, action) => {
            state.loadingNewsNotification = false;
            state.newsNotifications = state.newsNotifications.concat(action.payload);
        });
        builder.addCase(fetchNewsNotification.pending, (state, action) => {
            state.loadingNewsNotification = true;
        });
        builder.addCase(fetchNewsNotification.rejected, (state, action) => {
            state.loadingNewsNotification = false;
        });

        builder.addCase(fetchMessageNotification.fulfilled, (state, action) => {
            state.loadingMessageNotification = false;
            state.messageNotifications = state.messageNotifications.concat(action.payload);
        });
        builder.addCase(fetchMessageNotification.pending, (state, action) => {
            state.loadingMessageNotification = true;
        });
        builder.addCase(fetchMessageNotification.rejected, (state, action) => {
            state.loadingMessageNotification = false;
        });

        builder.addCase(fetchOthersNotification.fulfilled, (state, action) => {
            state.loadingOthersNotification = false;
            state.othersNotifications = state.othersNotifications.concat(action.payload);
        });
        builder.addCase(fetchOthersNotification.pending, (state, action) => {
            state.loadingOthersNotification = true;
        });
        builder.addCase(fetchOthersNotification.rejected, (state, action) => {
            state.loadingOthersNotification = false;
        });

        builder.addCase(fetchReadAllNewsNotification.fulfilled, (state, action) => {
            state.loadingReadAllNewsNotification = false;
        });
        builder.addCase(fetchReadAllNewsNotification.pending, (state, action) => {
            state.loadingReadAllNewsNotification = true;
        });
        builder.addCase(fetchReadAllNewsNotification.rejected, (state, action) => {
            state.loadingReadAllNewsNotification = false;
        });

        builder.addCase(fetchReadAllMessageNotification.fulfilled, (state, action) => {
            state.loadingReadAllMessageNotification = false;
        });
        builder.addCase(fetchReadAllMessageNotification.pending, (state, action) => {
            state.loadingReadAllMessageNotification = true;
        });
        builder.addCase(fetchReadAllMessageNotification.rejected, (state, action) => {
            state.loadingReadAllMessageNotification = false;
        });

        builder.addCase(fetchReadAllOthersNotification.fulfilled, (state, action) => {
            state.loadingReadAllOthersNotification = false;
        });
        builder.addCase(fetchReadAllOthersNotification.pending, (state, action) => {
            state.loadingReadAllOthersNotification = true;
        });
        builder.addCase(fetchReadAllOthersNotification.rejected, (state, action) => {
            state.loadingReadAllOthersNotification = false;
        });

        builder.addCase(fetchWhatsappNotification.fulfilled, (state, action) => {
            state.countUnreadWhatsappNotification = action.payload.total;
        });
        builder.addCase(fetchWhatsappNotification.pending, (state, action) => {});
        builder.addCase(fetchWhatsappNotification.rejected, (state, action) => {});

        builder.addCase(fetchCheckNotification.fulfilled, (state, action) => {
            const newsTemp = action.payload.find((item) => item.type === 'news');
            const messageTemp = action.payload.find((item) => item.type === 'message');
            const othersTemp = action.payload.find((item) => item.type === 'others');

            const newsUnreadMapTemp = {};
            const messageUnreadMapTemp = {};
            const othersUnreadMapTemp = {};

            newsTemp.data.forEach((item) => (newsUnreadMapTemp[item.notification_id] = item));
            messageTemp.data.forEach((item) => (messageUnreadMapTemp[item.notification_id] = item));
            othersTemp.data.forEach((item) => (othersUnreadMapTemp[item.notification_id] = item));

            let tasks = {};
            action.payload.forEach((element) => {
                element.data.forEach((item) => {
                    if (item?.Notification?.task_id) {
                        tasks[item.Notification.task_id] = item.Notification.task_id;
                    }
                });
            });

            state.listUnreadTask = tasks;

            state.countUnreadNewsNotification = newsTemp.count;
            state.unreadNewsNotification = newsTemp.data;
            state.unreadNewsNotificationMap = newsUnreadMapTemp;

            state.countUnreadMessageNotification = messageTemp.count;
            state.unreadMessageNotification = messageTemp.data;
            state.unreadMessageNotificationMap = messageUnreadMapTemp;

            state.countUnreadOthersNotification = othersTemp.count;
            state.unreadOthersNotification = othersTemp.data;
            state.unreadOthersNotificationMap = othersUnreadMapTemp;
        });
    }
});

const { actions, reducer } = notificationSlice;

export const {
    updatePageNewsNotifications,
    updatePageMessageNotifications,
    updatePageOthersNotifications,
    updateNewsNotifications,
    updateMessageNotifications,
    updateOthersNotifications,
    clearNotification,
    updateWhatsappNotification
} = actions;

export default reducer;
