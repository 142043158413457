import { toast } from 'react-toastify';
import { clearAuthData } from 'features/auth/store/authSlice';
import { updatePersonal } from 'features/personal/store/personalSlice';
import { render } from 'react-dom';
import TokenExpired from './components/TokenExpired';

const expectApiList = [
    "auth",
    "auth/social_login"
]


export function setupAxios(axios, store) {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    axios.interceptors.request.use(
        function (config) {
            /* Do not add Authorization for s3 request */
            if (config.url.startsWith('https://s3.amazonaws.com')) return config;
            if (config.url.startsWith('https://whatsapp-api-file.s3.ap-southeast-1.amazonaws.com')) return config;

            const controller = new AbortController();
            const access_token = localStorage.getItem('access_token');
            const access_token_exp = localStorage.getItem('access_token_exp');
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            /* Nếu có token thì gắn token vào Authorization
          - nếu đã có Authorization thì không gắn nữa
          - Tránh lỗi cứ lấy token cũ để gọi
          */
            if (access_token && !config.headers.Authorization) {
                config.headers.Authorization = 'Bearer ' + access_token;
            }
            if((!access_token || access_token_exp * 1000 < new Date().getTime()) && !expectApiList.includes(config.url) && !urlParams.get('access_token')) {
                render(<TokenExpired open={true}/>, document.getElementById("token-expired"));
                controller.abort();
            }
            return {
                ...config,
                signal: controller.signal
            };
        },
        function (error) {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        function (response) {
            /* Đối với các url này thì cần return response để check các header */
            if (['task/file_template/download'].includes(response.config.url)) {
                return response;
            }
            // Any status code that lie within the range of 2xx cause this function to trigger
            return response.data;
        },
        function (error) {
            const response = error.response;

            const showMessage = () => {
                let message = 'Somethings went wrong!';
                let content = '';
                if (response?.data?.message && typeof response?.data?.message === 'string') {
                    message = response?.data?.message;
                }
                if (response?.data?.data && typeof response?.data?.data === 'string') {
                    content = response?.data?.data;
                }
                toast.error(
                    <div className={'ml-2'}>
                        {message && <h1 className={'mb'}>{message}</h1>}
                        {content && <p className={'mt-2 text-xs'}>{content}</p>}
                    </div>
                );
            };

            switch (response?.status) {
                case 401:
                    store.dispatch(clearAuthData());
                    store.dispatch(updatePersonal(null));
                    if (response.config.url === 'auth/passport') {
                        showMessage();
                        break;
                    }
                    window.location.reload();
                    break;
                default:
                    /* Không show message lỗi khi get hình lỗi */
                    if (response.config.url === 'file/signed-url-s3/view') break;
                    /* Không show message lỗi khi send message whatsapp */
                    if(response.config.url === `${process.env.REACT_APP_WHATSAPP_API_URL}/message` && response.config.method === 'post') break;
                    showMessage();
                    break;
            }

            // Any status codes that falls outside the range of 2xx cause this function to trigger
            return Promise.reject(response);
        }
    );
}
