import dagre from 'dagre';

export const getLayoutedElements = (
    nodes,
    edges,
    nodeWidth = 450,
    nodeHeight = 270,
    direction = 'TB'
) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);
    
    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? 'left' : 'top';
        node.sourcePosition = isHorizontal ? 'right' : 'bottom';
        
        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2
        };
        
        return node;
    });
    // center a root node
    // swap contact member node position with node has positionX = 0
    const largestRoot = Math.max(...nodes.filter(node => node.id.includes('root')).map(node => node.position.x));

    nodes.map((node) => {
        if (node.id === 'root') {
            node.position = {
                ...node.position,
                x: largestRoot / 2,
            };
        }

        return node;
    })

    return { nodes, edges };
};
