import axios from 'axios';

const ENDPOINT = 'role';

export const roleApi = {
    getList: (params) =>
        axios.get(ENDPOINT, {
            params
        }),
    create: (data) => axios.post(ENDPOINT, data),
    update: (id, payload) => axios.put(ENDPOINT + '/' + id, payload),
    disable: (id) => axios.put(ENDPOINT + '/' + id + '/disable'),
    active: (id) => axios.put(ENDPOINT + '/' + id + '/active'),
    delete: (id) => axios.delete(ENDPOINT + '/' + id),
    getPermission: (id) => axios.get(ENDPOINT + '/' + id + '/permission'),
    getLimit: (id) => axios.get(ENDPOINT + '/' + id + '/limit'),
    updatePermission: (id, payload) => axios.post(ENDPOINT + '/' + id + '/permission', payload),
    updateLimit: (id, payload) => axios.post(ENDPOINT + '/' + id + '/limit', payload),
    getUserByRole: () => axios.get(ENDPOINT + '/user')
};
