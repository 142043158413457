export const BANK_ID_CAYE = 1;
export const BANK_ID_BANKONE = 13;
export const BANK_ID_CIM = 23;

export const CORPORATE_DOCUMENTS_TEMPLATE_ID = '10001';
export const CORPORATE_DOCUMENTS = [
    {
        name: 'Company docs',
        id: CORPORATE_DOCUMENTS_TEMPLATE_ID,
        parent_id: 99,
        titles: [
            {
                file_type_id: 5,
                file_name: 'Certification & Constitution',
                file_template_id: CORPORATE_DOCUMENTS_TEMPLATE_ID
            },
            {
                file_type_id: 8,
                file_name: 'Other Documents',
                file_template_id: CORPORATE_DOCUMENTS_TEMPLATE_ID
            }
        ]
    }
];
