import axios from 'axios';

const ENDPOINT = 'process_step';

export const processStepApi = {
    getList: (params) => axios.get(ENDPOINT, { params }),
    getRoleByProcessId: (id) => axios.get(ENDPOINT + '/' + id + '/role'),
    get: (id) => axios.get(ENDPOINT + '/' + id),
    create: (data) => axios.post(ENDPOINT, data),
    update: (id, payload) => axios.put(ENDPOINT + '/' + id, payload),
    updateRole: (id, payload) => axios.post(ENDPOINT + '/' + id + '/role', payload),
    delete: (id) => axios.delete(ENDPOINT + '/' + id)
};
