export const DIRECTOR = 'DIRECTOR';
export const CS_LEADER = 'CS_LEADER';
export const CS_MEMBER = 'CS_MEMBER';
export const ACCOUNTANT_LEADER = 'ACCOUNTANT_LEADER';
export const ACCOUNTANT_MEMBER = 'ACCOUNTANT_MEMBER';
export const LEGAL_LEADER = 'LEGAL_LEADER';
export const LEGAL_MEMBER = 'LEGAL_MEMBER';
export const COMPLIANCE_LEADER = 'COMPLIANCE_LEADER';
export const COMPLIANCE_MEMBER = 'COMPLIANCE_MEMBER';
export const BOOKKEEPER_LEADER = 'BOOKKEEPER_LEADER';
export const BOOKKEEPER_MEMBER = 'BOOKKEEPER_MEMBER';
export const TESTER_LEADER = 'Quality Control Leader';