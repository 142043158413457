import { useLocation } from 'react-router-dom';

/**
 * Custom hook to get query params from url
 * @returns {module:url.URLSearchParams}
 */
export const useQueryParams = () => {
    const location = useLocation();
    return new URLSearchParams(location.search);
};
