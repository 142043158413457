import { createSlice } from '@reduxjs/toolkit';
import { fetchLogin, fetchGetAccessTokenDetail, fetchLoginV2 } from './authThunk';
import { authApi } from 'api/authApi';

let permissions = [];
let permissionsMap = {};
let roles = [];
let rolesMap = {};
let limits = [];
if (localStorage.getItem('Permissions')) {
    try {
        permissions = JSON.parse(localStorage.getItem('Permissions'));
        permissions.forEach((permission) => {
            permissionsMap[permission] = permission;
        });
    } catch (e) {}
}
if (localStorage.getItem('Roles')) {
    try {
        roles = JSON.parse(localStorage.getItem('Roles'));
        roles.forEach((role) => {
            rolesMap[role] = role;
        });
    } catch (e) {}
}
if (localStorage.getItem('Limits')) {
    try {
        limits = JSON.parse(localStorage.getItem('Limits'));
    } catch (e) {}
}

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        loadingLogin: 'idle',
        access_token: localStorage.getItem('access_token') || null,
        access_token_exp: localStorage.getItem('access_token_exp') || null,
        refresh_token: localStorage.getItem('refresh_token') || null,
        refresh_token_exp: localStorage.getItem('refresh_token_exp') || null,
        Roles: roles,
        RolesMap: rolesMap,
        Permissions: permissions,
        PermissionsMap: permissionsMap,
        Limits: limits,

        hasNoAdminLayout: false,

        loadingLoginSocial: false,
        loginSocialSuccess: false,
        loginSocialSuccessAndWaitingToSetRole: false
    },
    reducers: {
        clearAuthData: (state) => {
            state.access_token = null;
            state.access_token_exp = null;
            state.refresh_token = null;
            state.refresh_token_exp = null;
            state.Roles = null;
            state.Permissions = null;

            state.loginSocialSuccess = false;
            state.loginSocialSuccessAndWaitingToSetRole = false;
            state.loadingLogin = 'idle';
            authApi.clearAuthData();
        },
        updateHasNoAdminLayout: (state, action) => {
            state.hasNoAdminLayout = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLogin.fulfilled, (state, action) => {
            // Add user to the state array
            const {
                access_token,
                access_token_exp,
                refresh_token,
                refresh_token_exp,
                Roles,
                Permissions,
                Limits
            } = action.payload;
            state.access_token = access_token;
            state.access_token_exp = access_token_exp;
            state.refresh_token = refresh_token;
            state.refresh_token_exp = refresh_token_exp;
            state.Limits = Limits;
            state.Roles = Roles;
            state.Permissions = Permissions;
            state.RolesMap = Roles.reduce((acc, cur) => ({ ...acc, [cur]: cur }), {});
            state.PermissionsMap = Permissions.reduce((acc, cur) => ({ ...acc, [cur]: cur }), {});
            state.loadingLogin = 'idle';
            authApi.saveAuthData(action.payload);
        });
        builder.addCase(fetchLogin.pending, (state, action) => {
            state.loadingLogin = 'loading';
        });
        builder.addCase(fetchLogin.rejected, (state, action) => {
            state.loadingLogin = 'idle';
        });
        builder.addCase(fetchGetAccessTokenDetail.fulfilled, (state, action) => {
            // Add user to the state array
            const {
                access_token,
                access_token_exp,
                refresh_token,
                refresh_token_exp,
                Roles,
                Permissions,
                Limits
            } = action.payload;
            state.access_token = access_token;
            state.access_token_exp = access_token_exp;
            state.refresh_token = refresh_token;
            state.refresh_token_exp = refresh_token_exp;
            state.Limits = Limits;
            state.Roles = Roles;
            state.Permissions = Permissions;
            state.RolesMap = Roles.reduce((acc, cur) => ({ ...acc, [cur]: cur }), {});
            state.PermissionsMap = Permissions.reduce((acc, cur) => ({ ...acc, [cur]: cur }), {});
            state.loadingLogin = 'idle';
            authApi.saveAuthData(action.payload);
        });
        builder.addCase(fetchGetAccessTokenDetail.pending, (state, action) => {
            state.loadingLogin = 'loading';
        });
        builder.addCase(fetchGetAccessTokenDetail.rejected, (state, action) => {
            state.loadingLogin = 'idle';
        });
        builder.addCase(fetchLoginV2.fulfilled, (state, action) => {
            const key = [
                'Permissions',
                'Roles',
                'access_token',
                'access_token_exp',
                'refresh_token',
                'refresh_token_exp'
            ];
            if (
                !(action.payload && Object.keys(action.payload).every((item) => key.includes(item)))
            ) {
                state.loginSocialSuccessAndWaitingToSetRole = true;
                state.loadingLoginSocial = false;
                return;
            }
            // Add user to the state array
            const {
                access_token,
                access_token_exp,
                refresh_token,
                refresh_token_exp,
                Roles,
                Permissions,
                Limits
            } = action.payload;
            state.access_token = access_token;
            state.access_token_exp = access_token_exp;
            state.refresh_token = refresh_token;
            state.refresh_token_exp = refresh_token_exp;
            state.Limits = Limits;
            state.Roles = Roles;
            state.Permissions = Permissions;
            state.RolesMap = Roles.reduce((acc, cur) => ({ ...acc, [cur]: cur }), {});
            state.PermissionsMap = Permissions.reduce((acc, cur) => ({ ...acc, [cur]: cur }), {});
            state.loadingLoginSocial = false;
            state.loginSocialSuccess = true;
            authApi.saveAuthData(action.payload);
        });
        builder.addCase(fetchLoginV2.pending, (state, action) => {
            state.loadingLoginSocial = true;
            state.loginSocialSuccess = false;
            state.loginSocialSuccessAndWaitingToSetRole = false;
        });
        builder.addCase(fetchLoginV2.rejected, (state, action) => {
            state.loadingLoginSocial = false;
            state.loginSocialSuccess = false;
            state.loginSocialSuccessAndWaitingToSetRole = false;
        });
    }
});

// Extract the action creators object and the reducer
const { actions, reducer } = authSlice;
// Extract and export each action creator by name
export const { clearAuthData, updateHasNoAdminLayout } = actions;
// Export the reducer, either as a default or named export
export default reducer;
