import { Button, Dialog } from '@mui/material';
import { LS_REMEMBER_PATH } from 'constants';

const TokenExpired = ({ open }) => {

    const handleClick = () => {
        localStorage.setItem(LS_REMEMBER_PATH, window.location.pathname + (window.location.search || ''));
        window.location.href = "/auth/login"
    }
    return (
        <Dialog open={open}>
                <div className='text-center py-10 px-20'>
                    <strong className='mt-4 text-2xl'>Your session has expired</strong>
                    <p className='mb-4 text-lg'>Please login again to continue</p>
                    <Button variant="contained" sx={{ textTransform: 'none', padding: "6px 24px" }} onClick={() => handleClick()}>Agree</Button>
                </div>
        </Dialog>
    );
};

export default TokenExpired;
