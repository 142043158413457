import * as bbcincorp from './edit-bbcincorp';
import * as bbcincorpSg from './edit-bbcincorp-sg';
import * as gleads from './edit-gleads';
import * as v2a from './edit-v2a';
import * as arrivalHub from './edit-arrival-hub';

export const SERVICE_EDIT = {
    BBCINCORP: bbcincorp.SERVICE_EDIT,
    BBCINCORP_SG: bbcincorpSg.SERVICE_EDIT,
    GLEADS: gleads.SERVICE_EDIT,
    VISA2ASIA: v2a.SERVICE_EDIT,    
    ARRIVALHUB: arrivalHub.SERVICE_EDIT,

};
