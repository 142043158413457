import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { usePermission } from 'hook';

function Index({ permissions = [], children, forRouting = false }) {
    const { hasPermissions } = usePermission();

    if (hasPermissions(permissions)) return children;

    if (forRouting) return <Navigate to={'/error/403'} />;

    return <></>;
}

export default Index;
