import { createAsyncThunk } from '@reduxjs/toolkit';
import { authApi } from 'api/authApi';

export const fetchLogin = createAsyncThunk('auth/fetchLogin', async (payload, thunkAPI) => {
    try {
        const response = await authApi.login(payload);
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const fetchLoginV2 = createAsyncThunk('auth/fetchLoginV2', async (accessToken, thunkAPI) => {
    try {
        const response = await authApi.loginV2(accessToken);
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const fetchGetAccessTokenDetail = createAsyncThunk(
    'auth/fetchGetAccessTokenDetail',
    async (accessToken, thunkAPI) => {
        try {
            const response = await authApi.getAccessTokenDetail(accessToken);
            const newResponse = { ...response.data };
            newResponse.access_token = accessToken;
            return newResponse;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);
