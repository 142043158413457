import axios from 'axios';

const ENDPOINT = 'customer';

export const customerApi = {
    getList: (params) =>
        axios.get(ENDPOINT, {
            params
        }),
    getListOwn: (params) =>
        axios.get(ENDPOINT + '/own', {
            params
        }),
    getCustomerHasTask: (value, params = {}) => {
        const defaultParams = {
            order_sort: 'asc',
            order_by: 'first_name',
            fields: '[]',
            filter: `{"search":"${value}"}`
        };
        return axios.get(`${ENDPOINT}/order_has_task`, { params: { ...defaultParams, ...params } });
    },
    get: (id) => axios.get(ENDPOINT + '/' + id),
    getOwn: (id) => axios.get(ENDPOINT + '/own/' + id),
    getV2: (id, params) => axios.get(ENDPOINT + '/v2/' + id, { params }),
    getV2Own: (id, params) => axios.get(ENDPOINT + '/own/v2/' + id, { params }),
    delete: (id) => axios.delete(ENDPOINT + '/' + id),
    update: (id, data) => axios.put(ENDPOINT + '/' + id, data),
    updateOwn: (id, data) => axios.put(ENDPOINT + '/own/' + id, data),
    getListLazy: (params) => axios.get(ENDPOINT + '/lazy', { params }),
    create: (data) => axios.post(ENDPOINT, data),
    merge: (data) => axios.post(ENDPOINT + '/merge/', data),
    generateUrlMailChimp: (data) => axios.post(ENDPOINT + '/generate_url_mailchimp', data),
    getTags: (mailChimpId) => axios.get(ENDPOINT + '/tags/' + mailChimpId),
    updateTag: (id, data) => axios.post(ENDPOINT + '/tags/' + id, data),
    updateCredit: (id, data) => axios.put(ENDPOINT + `/${id}/credit_amount`, data)
};
