import { createAsyncThunk } from '@reduxjs/toolkit';
import { processApi, taskApi } from 'api';

export const fetchGetProcessTree = createAsyncThunk(
    'task/fetchGetProcessTree',
    async ({ id, params }, thunkAPI) => {
        try {
            const response = await taskApi.getProcessTree(id, params);
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const fetchGetServiceTypes = createAsyncThunk(
    'task/fetchGetServiceTypes',
    async (id, thunkAPI) => {
        try {
            const response = await taskApi.getServiceTypes(id);
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const fetchGetProcessDetail = createAsyncThunk(
    'task/fetchGetProcessDetail',
    async (id, thunkAPI) => {
        try {
            const response = await processApi.get(id);
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const fetchGetTaskDetail = createAsyncThunk(
    'task/fetchGetTaskDetail',
    async (id, thunkAPI) => {
        try {
            const response = await taskApi.get(id);
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const fetchApproveProcess = createAsyncThunk(
    'task/fetchApproveProcess',
    async (payload, thunkAPI) => {
        try {
            const response = await processApi.approve(payload);
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const fetchCloseAndRefundProcess = createAsyncThunk(
    'task/fetchCloseAndRefundProcess',
    async (payload, thunkAPI) => {
        try {
            const response = await processApi.terminate(payload);
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const fetchRejectProcess = createAsyncThunk(
    'task/fetchRejectProcess',
    async (payload, thunkAPI) => {
        try {
            const response = await processApi.reject(payload);
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);
