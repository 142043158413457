import axios from 'axios';

const ENDPOINT = 'notification';

export const notificationApi = {
    getNotifications: (params) => {
        return axios.get(ENDPOINT, { params });
    },
    checkNotifications: () => {
        return axios.get(`${ENDPOINT}/check`);
    },
    readAll: (payload) => {
        return axios.put(`${ENDPOINT}/read`, payload);
    },
    read: (id) => {
        return axios.put(`${ENDPOINT}/read/${id}`);
    }
};
