import axios from 'axios';

const ENDPOINT = 'company_event';

export const companyEventApi = {
    get: (id) => axios.get(ENDPOINT + '/' + id),
    delete: (id) => axios.delete(ENDPOINT + '/' + id),
    viewFile: (id, params) =>
        axios.get(ENDPOINT + '/' + id + '/file/view', { params })
};
