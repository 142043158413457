import { yupResolver } from '@hookform/resolvers/yup';
import ImageIcon from '@mui/icons-material/Image';
import {
    Box,
    Button,
    IconButton,
    LinearProgress,
    TextField
} from '@mui/material';
import BlockWrapper from 'components/BlockWrapper';
import MImage from 'components/MImage';
import { FileBox, LoadingFilesSkeleton } from 'components/PreviewFileUploadBox';
import InputField from 'components/form-controls/InputField';
import { isFilesAccept, scanFile } from 'helper/scanfile';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

const schema = yup
    .object({
        description: yup.string().required('')
    })
    .required();

function NoteForm({
    onSubmit,
    data = null,
    detail = null,
    onCancel,
    loading = false,
    disabled = false,
    onFocusChange,
    hiddenUploadFile = false,
    hiddenTitle = false
}) {
    const form = useForm({
        defaultValues: {
            id: null,
            title: '',
            description: ''
        },
        resolver: yupResolver(schema)
    });
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [deletedFiles, setDeletedFiles] = useState([]);
    const uploadInputRef = useRef(null);
    const [isFocus, setIsFocus] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [isInvalidFile, setInvalidFile] = useState(false);
    const scannedFilesLength = useRef(0);

    useEffect(() => {
        if (!detail) {
            form.reset();
            return;
        }

        form.setValue('id', detail.id);
        form.setValue('title', detail.title || '');
        form.setValue('description', detail.description);
        setIsFocus(true);
    }, [detail]);

    const handleCancel = () => {
        form.reset();
        uploadInputRef.current.value = '';
        setSelectedFiles([]);
        setDeletedFiles([]);
        setIsFocus(false);
        onCancel && onCancel();
    };

    const onSelectFile = async (e) => {
        if (!isFilesAccept(e.target.files)) {
            toast.error(
                'Accept extensions: .jpg, .jpeg, .png, .pdf, .doc, .docx, .mov, .mp4, .avi, .wmv'
            );
            uploadInputRef.current.value = '';
            return;
        }

        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFiles([]);
            return;
        }

        setLoadingFile(true);
        scannedFilesLength.current = e.target.files.length;
        const { scannedFiles, isValid, failToScan } = await scanFile(e.target.files);
        setLoadingFile(false);
        if (failToScan) {
            uploadInputRef.current.value = '';
            return;
        };
        
        if (!isInvalidFile && !isValid) {
            setInvalidFile(true);
        }

        setSelectedFiles([...selectedFiles, ...scannedFiles]);
        if (uploadInputRef.current) {
            uploadInputRef.current.value = '';
        }
    };

    const handleSubmit = (value) => {
        onSubmit(value, selectedFiles, deletedFiles, handleCancel);
    };

    const handleRemoveSelectedFile = (file, index) => {
        const remainFiles = selectedFiles.filter((item, idx) => idx !== index);

        if (!file.isValid) {
            const allFileValid = !remainFiles.length
                ? true
                : remainFiles.every((selectedFile) => selectedFile?.isValid);
            if (allFileValid) setInvalidFile(false);
        }

        return setSelectedFiles(remainFiles);
    };

    const handleRemoveSavedFile = (file) => {
        setDeletedFiles([...deletedFiles, { id: file.id, is_deleted: true }]);
    };

    useLayoutEffect(() => {
        onFocusChange && onFocusChange(isFocus);
    }, [isFocus]);

    if (!isFocus)
        return (
            <BlockWrapper>
                <TextField
                    variant={'standard'}
                    fullWidth={true}
                    label={'Take a note'}
                    disabled={disabled}
                    onFocus={() => setIsFocus(true)}
                />
            </BlockWrapper>
        );

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <BlockWrapper sx={{ position: 'relative' }}>
                {loading && (
                    <Box
                        position={'absolute'}
                        top={0}
                        left={0}
                        width={'100%'}>
                        <LinearProgress />
                    </Box>
                )}
                {!hiddenTitle && (
                    <InputField
                        name={'title'}
                        sx={{ mb: 2 }}
                        form={form}
                        disabled={loading || disabled}
                        label={'Title'}
                    />
                )}
                <InputField
                    autoFocus
                    name={'description'}
                    form={form}
                    label={'Take a note'}
                    multiline={true}
                    rows={3}
                    disabled={loading || disabled}
                />
                <br />
                <br />

                {detail?.Files?.filter(
                    (file) => !deletedFiles.map((deletedFile) => deletedFile.id).includes(file.id)
                ).map((file) => (
                    <MImage
                        label={file.s3_path}
                        s3Path={file.s3_path}
                        imgProps={{
                            style: {
                                width: '150px',
                                height: '100px',
                                objectFit: 'contain',
                                border: '1px solid #ccc',
                                display: 'inline-block'
                            },
                            className: 'mr-2 mb-2'
                        }}
                        key={file.id}
                        onRemove={() => handleRemoveSavedFile(file)}
                        disabled={loading || disabled}
                    />
                ))}

                <Box
                    display={'flex'}
                    flexWrap={'wrap'}>
                    {selectedFiles.map((selectedFile, index) => (
                        <FileBox
                            name={selectedFile.name}
                            key={index}
                            url={URL.createObjectURL(selectedFile)}
                            onRemove={() => handleRemoveSelectedFile(selectedFile, index)}
                            disabled={loading || disabled}
                            type={selectedFile.type}
                            isValid={selectedFile?.isValid}
                        />
                    ))}
                    {loadingFile && (
                        <LoadingFilesSkeleton filesLength={scannedFilesLength?.current} />
                    )}
                </Box>
                <Box
                    display={'flex'}
                    px={1}
                    justifyContent={'space-between'}>
                    {!hiddenUploadFile ? (
                        <label htmlFor={'upload-file-note-container'}>
                            <input
                                ref={uploadInputRef}
                                style={{ display: 'none' }}
                                id="upload-file-note-container'"
                                onChange={onSelectFile}
                                multiple={true}
                                type="file"
                            />
                            <IconButton
                                onClick={() => uploadInputRef.current.click()}
                                disabled={loading || disabled || loadingFile}
                                size={'small'}>
                                <ImageIcon />
                            </IconButton>
                        </label>
                    ) : (
                        <Box></Box>
                    )}

                    <Box>
                        <Button
                            onClick={handleCancel}
                            disabled={loading || disabled || loadingFile}
                            size={'small'}>
                            Cancel
                        </Button>
                        <Button
                            type={'submit'}
                            disabled={loading || disabled || loadingFile || isInvalidFile}
                            size={'small'}>
                            Save
                        </Button>
                    </Box>
                </Box>
            </BlockWrapper>
        </form>
    );
}

export default NoteForm;
