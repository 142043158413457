import axios from 'axios';

const ENDPOINT = 'user_customer';

export const userCustomerApi = {
    getList: (params) =>
        axios.get(ENDPOINT, {
            params
        }),
    get: (id) => axios.get(ENDPOINT + '/' + id),
    delete: (id) => axios.delete(ENDPOINT + '/' + id),
    update: (id, data) => axios.put(ENDPOINT + '/' + id, data),
    create: (data) => axios.post(ENDPOINT, data),
    updatePassword: (id, data) => axios.put(ENDPOINT + '/password/' + id, data)
};
