import * as React from 'react';
import {
    DialogTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Box
} from '@mui/material';
import { debounce } from 'lodash';

function MConfirmation(
    {
        children,
        onApprove,
        title,
        content,
        disabled,
        cancelLabel = 'Cancel',
        confirmLabel = 'Confirm',
        panelProps = {}
    },
    ref
) {
    const [open, setOpen] = React.useState(false);
    const [mTitle, setMTitle] = React.useState('');
    const [mContent, setMContent] = React.useState('');
    const [data, setData] = React.useState(null);

    React.useImperativeHandle(
        ref,
        () => ({
            setOpen(value) {
                setOpen(value);
            },
            setTitle(title) {
                setMTitle(title);
            },
            setContent(content) {
                setMContent(content);
            },
            setData(data) {
                setData(data);
            }
        }),
        []
    );

    const handleClickOpen = () => {
        !disabled && setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleApprove = () => {
        handleClose();
        onApprove(data);
    };

    const callDebounce = debounce(() => {
        handleApprove();
    }, 300);

    return (
        <>
            {children ? (
                <Box
                    {...panelProps}
                    onClick={handleClickOpen}>
                    {children}
                </Box>
            ) : null}
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{title || mTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content || mContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button
                        variant={'outlined'}
                        onClick={handleClose}
                        autoFocus>
                        {cancelLabel}
                    </Button>
                    {/* {onApprove ? <Button onClick={callDebounce}>{confirmLabel}</Button> : null} */}
                    {onApprove ? (
                        <Button
                            onClick={(event) => {
                                if(event.detail > 1) return; // nếu user nhấn 2 lần liên tục sẽ không submit
                                handleClose();
                                onApprove(data);
                            }}>
                            {confirmLabel}
                        </Button>
                    ) : null}
                </DialogActions>
            </Dialog>
        </>
    );
}

export default React.forwardRef(MConfirmation);
