import axios from 'axios';

const ENDPOINT = 'enum';

export const enumApi = {
    getCountry: () => axios.get(ENDPOINT + '/country'),
    getAgentType: () => axios.get(ENDPOINT + '/agent_type'),
    getSyslogType: () => axios.get(ENDPOINT + '/syslog_type'),
    getOrderStatus: () => axios.get(ENDPOINT + '/order_status'),
    getCountryHasService: () => axios.get(ENDPOINT + '/country_has_service'),
    getCountryCooperative: () => axios.get(ENDPOINT + '/country_cooperative'),
    getWebsite: () => axios.get(ENDPOINT + '/websites'),
    getSupportCaseType: () => axios.get(ENDPOINT + '/support_case_type'),
    getSupportCaseStatus: () => axios.get(ENDPOINT + '/support_case_status'),
    getSupportCasePriority: () => axios.get(ENDPOINT + '/support_case_priority'),
    getSupporter: (params) => axios.get(ENDPOINT + '/supporter', { params }),
    getPermissionGroup: () => axios.get(ENDPOINT + '/permission_group'),
    getLimitType: (params) => axios.get(ENDPOINT + '/limit_type', { params }),
    getPackageType: () => axios.get(ENDPOINT + '/package_type'),
    getEntityType: () => axios.get(ENDPOINT + '/entity_type'),
    getServiceType: () => axios.get(ENDPOINT + '/service_type'),
    getFeeType: () => axios.get(ENDPOINT + '/fee_type'),
    getServiceUnit: () => axios.get(ENDPOINT + '/service_unit'),
    getService: (params) => axios.get(ENDPOINT + '/service', { params }),
    getRole: () => axios.get(ENDPOINT + '/role'),
    getSubscription: () => axios.get(ENDPOINT + '/subscription'),
    getGender: () => axios.get(ENDPOINT + '/gender'),
    getCompanyStatus: () => axios.get(ENDPOINT + '/company_status'),
    getPackage: () => axios.get(ENDPOINT + '/package'),
    getCustomerStatus: () => axios.get(ENDPOINT + '/customer_status'),
    getCustomerType: () => axios.get(ENDPOINT + '/customer_type'),
    getContactFrom: () => axios.get(ENDPOINT + '/contact_from'),
    getGaChanel: () => axios.get(ENDPOINT + '/ga_channel'),
    getCampaignAd: () => axios.get(ENDPOINT + '/campaign'),
    getVisaOption: () => axios.get(ENDPOINT + '/visa_option'),
    getIndustry: () => axios.get(ENDPOINT + '/industry'),
    getMailChimpTag: () => axios.get(ENDPOINT + '/mailchimp_tag'),
    getAgent: (params) => axios.get(ENDPOINT + '/agent', { params }),
    getProcessStep: () => axios.get(ENDPOINT + '/process_step'),
    getBusinessActivities: (params) => axios.get(ENDPOINT + '/business_activity', { params }),
    getFileType: (params) => axios.get(ENDPOINT + '/file_type', { params }),
    getFileTemplate: (params) => axios.get(ENDPOINT + '/file_template', { params }),
    getBankBranch: (params) => axios.get(ENDPOINT + '/bank_branch', { params }),
    getCurrency: (params) => axios.get(ENDPOINT + '/currency', { params }),
    getApiMethod: (params) => axios.get(ENDPOINT + '/api_method', { params }),
    getApiGroup: (params) => axios.get(ENDPOINT + '/api_group', { params }),
    getApiStatus: (params) => axios.get(ENDPOINT + '/api_status', { params }),
    getCompanyMemberType: (params) => axios.get(ENDPOINT + '/company_member_type', { params }),
    getTypeMember: (params) => axios.get(ENDPOINT + '/type_member', { params })
};
