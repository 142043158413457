import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/store/authSlice';
import personalSlice from '../features/personal/store/personalSlice';
import notificationSlice from '../features/notification/store/notificationSlice';
import taskSlice from '../features/task/store/taskSlice';
import enumSlice from '../features/enum/store/enumSlice';
import whatsAppSlice from '../features/whatsapp/store/whatsAppSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        personal: personalSlice,
        notification: notificationSlice,
        task: taskSlice,
        enum: enumSlice,
        whatsapp: whatsAppSlice
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});
