import { ARRIVALHUB_ID } from "constants";

export const CUSTOMER_EDIT = {
    // address: '',
    // airport_id: null,
    // birthday: null,
    // business_description: '',
    // campaign_ads_id: null,
    // city: '',
    // client_id: '',
    // company_address: '',
    // company_address_city: '',
    // company_address_country_id: null,
    // company_address_postal_code: '',
    // company_address_state: '',
    // company_country_id: null,
    // company_name: '',
    // company_registration_no: '',
    // company_suffix_id: null,
    // contact_from_id: null,
    // country_id: null,
    // customer_status_id: null,
    // customer_type_id: null,
    // email: '',
    // entity_type_id: null,
    // entry_time: '',
    // exit_time: '',
    // first_name: '',
    // ga_channel_id: '',
    // gender_id: null,
    // last_name: '',
    // mailchimp_id: '',
    // other_type: '',
    // passport: '',
    // person_id: null,
    // phone_country_id: null,
    // phone_number: '',
    // postal_code: '',
    // promotion_id: '',
    // skype: '',
    // state: '',
    // title_name_id: null,
    // visa_purpose_id: null,
    // visa_type_id: null,
    website_id: ARRIVALHUB_ID,
    // cc_email: '[]',
    // business_activity_industry_id: null,
    // credit_amount: 0,
    // credit_description: ''
};
