export const DIRECTOR_TYPE_ID = 1;
export const SHAREHOLDER_TYPE_ID = 2;
export const UBO_TYPE_ID = 3;
export const SECRETARY_TYPE_ID = 4;
export const PRESIDENT_TYPE_ID = 5;
export const TREASURER_TYPE_ID = 6;
export const CONTACT_PERSON_ID = 7;
export const PAYER_TYPE_ID = 8;

export const COMPANY_MEMBER_TYPE = {
    [DIRECTOR_TYPE_ID]: {
        name: 'Director',
        color: 'primary'
    },
    [SHAREHOLDER_TYPE_ID]: {
        name: 'Shareholder',
        color: 'success'
    },
    [UBO_TYPE_ID]: {
        name: 'UBO',
        color: 'warning'
    },
    [SECRETARY_TYPE_ID]: {
        name: 'Secretary',
        color: 'default'
    },
    [PRESIDENT_TYPE_ID]: {
        name: 'President',
        color: 'default'
    },
    [TREASURER_TYPE_ID]: {
        name: 'Treasurer',
        color: 'default'
    },
    [CONTACT_PERSON_ID]: {
        name: 'Contact Information',
        color: 'error'
    },
    [PAYER_TYPE_ID]: {
        name: 'Payer',
        color: 'default'
    }
};
