import { hasChildren } from 'helper';
import ShowWithPermissionCheck from 'components/ShowWithPermissionCheck';
import React from 'react';
import { SingleLevel } from './SingleLevel';
import { MultiLevel } from './MultiLevel';

export const MMenuItem = ({ item, setOpenParent, isCloseCollapse }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;


    return (
        <ShowWithPermissionCheck permissions={item.permissions}>
            <Component
                item={item}
                setOpenParent={setOpenParent}
                isCloseCollapse={isCloseCollapse}
            />
        </ShowWithPermissionCheck>
    );
};
