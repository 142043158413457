import { createSlice } from '@reduxjs/toolkit';
import { fetchPersonal } from './personalThunk';

const personalSlice = createSlice({
    name: 'personal',
    initialState: {
        loadingPersonal: 'idle',
        personal: null
    },
    reducers: {
        updatePersonal: (state, action) => {
            state.personal = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPersonal.fulfilled, (state, action) => {
            state.loadingPersonal = 'idle';
            state.personal = action.payload;
        });
        builder.addCase(fetchPersonal.pending, (state, action) => {
            state.loadingPersonal = 'loading';
        });
        builder.addCase(fetchPersonal.rejected, (state, action) => {
            state.loadingPersonal = 'idle';
        });
    }
});

const { actions, reducer } = personalSlice;

export const { updatePersonal } = actions;

export default reducer;
