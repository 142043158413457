import axios from 'axios';

const ENDPOINT = 'company';

export const companyApi = {
    getList: (params) =>
        axios.get(ENDPOINT, {
            params
        }),
    getListOwn: (params) =>
        axios.get(ENDPOINT + '/own', {
            params
        }),
    getCompanyOrder: (params) =>
        axios.get(ENDPOINT + '/order', {
            params
        }),
    get: (id) => axios.get(ENDPOINT + '/' + id),
    delete: (id) => axios.delete(ENDPOINT + '/' + id),
    update: (id, data) => axios.put(ENDPOINT + '/' + id, data),
    create: (data) => axios.post(ENDPOINT + '/new-company', data),
    createOwn: (data) => axios.post(ENDPOINT + '/own/new-company', data),
    updateOwn: (id, data) => axios.put(ENDPOINT + '/own/' + id, data),
    addProposedName: (taskId, payload) =>
        axios.post(ENDPOINT + '/' + 'propose_name' + '/' + taskId, payload),
    updateProposedName: (id, payload) =>
        axios.put(ENDPOINT + '/' + 'propose_name' + '/' + id, payload),
    genSeal: (data) => axios.post(ENDPOINT + '/gen_seal', data),
    files: (id) => axios.get(ENDPOINT + '/' + id + '/file'),
    getWithShareTransfer: (id) => axios.get(ENDPOINT + '/' + id + '/share_own_interest'),
    getComplianceEvent: (id) => axios.get(ENDPOINT + '/' + id + '/event'),
    getCompletedEvent: (id, params) =>
        axios.get(ENDPOINT + '/' + id + '/completed_event', { params }),
    getFileCategories: (id) => axios.get(ENDPOINT + '/' + id + '/calendar_category_file'),
    getYearsList: (id, params) =>
        axios.get(ENDPOINT + '/' + id + '/years_not_buy_event', { params }),
    createEvent: (id, payload) => axios.post(ENDPOINT + '/' + id + '/event', payload),
    getTransactionEvent: (id, params) => axios.get(ENDPOINT + '/' + id + '/transaction_event', { params })
};
