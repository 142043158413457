/* Function to generate Time options
 * @param from: 0, 1, 2, ... 23
 * @param to: 0, 1, 2, ... 23
 * @param interval: 5, 10, 15
 * @param endWithTo: add end option is to value or not */
export const generateTimesOptions = (from, to, interval = 5, endWithTo = true) => {
    if (isNaN(from) || from < 0) {
        from = 0;
    }
    if (isNaN(to) || to > 23) {
        to = 23;
    }
    if (to < from) return [];
    const result = [];
    const minutes = [];
    if (isNaN(interval) || interval === 0 || interval > 60) {
        minutes.push('00');
    } else {
        for (let i = 0; i < 60; i += interval) {
            minutes.push(i.toString().padStart(2, '0'));
        }
    }
    for (let i = from; i < to; i++) {
        minutes.forEach((minute) => {
            result.push({
                id: i.toString().padStart(2, '0') + ':' + minute,
                name: i.toString().padStart(2, '0') + ':' + minute
            });
        });
    }
    if (endWithTo) {
        result.push({ id: to.toString() + ':00', name: to.toString() + ':00' });
    }
    return result;
};

/**
 * Hàm dùng để nối date month year lại thành 1 string theo format truyền vào
 * @param format {'dd/mm/yyyy' | 'yyyy/mm/dd' | 'dd-mm-yyyy' | 'yyyy-mm-dd' | *}
 * @param date {String} date
 * @param month {String} month
 * @param year {String} year
 * @returns {string}
 */
export const getDateStringWithFormat = (format, date, month, year) => {
    switch (format) {
        case 'dd/mm/yyyy':
            return [date, month, year].join('/');
        case 'yyyy/mm/dd':
            return [year, month, date].join('/');
        case 'dd-mm-yyyy':
            return [date, month, year].join('-');
        case 'yyyy-mm-dd':
            return [year, month, date].join('-');
        default:
            return [date, month, year].join('/');
    }
};

/**
 *
 * @param dateTime {number} milliseconds
 * @param timezone {number} timezone
 * @param format {'dd/mm/yyyy' | 'yyyy/mm/dd' | 'dd-mm-yyyy' | 'yyyy-mm-dd' | *} format
 * @returns {string|*}
 */
export const getDateStringWithTimeZone = (dateTime, timezone = 0, format) => {
    let dateUTC = new Date(dateTime);
    if (dateUTC) {
        dateUTC.setUTCHours(dateUTC.getUTCHours() + timezone);
        let date = dateUTC.getUTCDate().toString().padStart(2, '0');
        let month = (dateUTC.getUTCMonth() + 1).toString().padStart(2, '0');
        let year = dateUTC.getUTCFullYear().toString();
        return getDateStringWithFormat(format, date, month, year);
    }
    throw '';
};

/**
 *
 * @param dateTime
 * @param timezone
 * @returns {string}
 */
export const getTimeStringWithTimeZone = (dateTime, timezone = 0) => {
    let dateUTC = new Date(dateTime);
    if (dateUTC) {
        dateUTC.setUTCHours(dateUTC.getUTCHours() + timezone);
        let hour = dateUTC.getUTCHours().toString().padStart(2, '0');
        let minute = dateUTC.getUTCMinutes().toString().padStart(2, '0');
        return `${hour}:${minute}`;
    }
    throw '';
};
