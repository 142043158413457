import axios from 'axios';

const ENDPOINT = 'support_case_cs';

export const supportCaseApi = {
    getList: (params) =>
        axios.get(ENDPOINT, {
            params
        }),
    getListOwn: (filter) =>
        axios.get(ENDPOINT + '/own', {
            params: filter
        }),
    get: (id) => axios.get(ENDPOINT + '/caseDetail/' + id),
    getMessages: (id) => axios.get(ENDPOINT + '/message?support_case_id=' + id),
    delete: (id) => axios.delete(ENDPOINT + '/' + id),
    update: (id, data) => axios.put(ENDPOINT + '/' + id, data),
    create: (data) => axios.post(ENDPOINT + '/message', data)
};
