import axios from 'axios';

const ENDPOINT = 'file';

export const fileApi = {
    getList: (filter) =>
        axios.get(ENDPOINT + '/all', {
            params: filter
        }),
    viewFile: (filter) =>
        axios.get(ENDPOINT + '/signed-url-s3/view', {
            params: filter
        }),
    bookkeeperViewFile: (filter) =>
        axios.get('bookkeeper_process/' + ENDPOINT + '/signed-url-s3/view', {
            params: filter
        }),
    viewFileCompany: (companyId, filter) =>
        axios.get(`company/${companyId}/file/view`, {
            params: filter
        }),
    getSignedUrlS3: (files) => axios.put(ENDPOINT + '/signed-url-s3/put', { files }),
    createDbFile: (data) => axios.post(ENDPOINT + '/create', data),
    postFile: (formData) => axios.post(ENDPOINT, formData),
    delete: (id) => axios.delete(ENDPOINT + '/' + id),
    uploadImage: (formData) => axios.post(ENDPOINT, formData),
    uploadFile: (formData) => axios.put(ENDPOINT, formData),
    viewSeal: (id) => axios.get(ENDPOINT + '/view_seal/' + id)
};
