import React from 'react';
import { Box, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

function FileBox({ url, name, onRemove = () => {}, disabled = false, type, isValid }) {
    return (
        <Box
            width={'145px'}
            mb={2}
            mr={1}>
            <Box
                className={`border-2 border-solid ${
                    isValid ? 'border-green-400' : 'border-red-600'
                }`}>
                <Box
                    mr={1}
                    mb={1}
                    border={'1px solid #ccc'}
                    position={'relative'}
                    width={'100%'}
                    height={'100px'}
                    overflow={'hidden'}
                    display={'inline-block'}>
                    <img
                        style={{ width: '100%', height: '100px', objectFit: 'contain' }}
                        src={type.includes('image') ? url : '/images/file_upload.png'}
                        alt={name}
                    />

                    <Box
                        position={'absolute'}
                        bottom={0}
                        right={0}>
                        <Tooltip
                            title="Delete file"
                            placement="top"
                            arrow>
                            <IconButton
                                onClick={onRemove}
                                disabled={disabled}
                                size={'small'}>
                                <CloseIcon
                                    fontSize={'small'}
                                    sx={{ stroke: '#fff', strokeWidth: 0.6 }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Box className="flex items-center">
                    {isValid ? (
                        <CheckCircleIcon className="text-green-400" />
                    ) : (
                        <ErrorIcon className="text-red-600" />
                    )}
                    <Tooltip
                        title={name}
                        placement={'bottom'}>
                        <Typography
                            width={'150px'}
                            noWrap>
                            {name}
                        </Typography>
                    </Tooltip>
                </Box>
            </Box>
            {!isValid && (
                <Typography className="text-red-600">
                    Invalid file. Please upload a valid file.
                </Typography>
            )}
        </Box>
    );
}

function LoadingFilesSkeleton({ filesLength = 1 }) {
    const loadingItems = Array(filesLength).fill(null);

    return (
        <>
            {loadingItems.map((item) => (
                <Box
                    width={'145px'}
                    mb={2}
                    mr={1}>
                    <Skeleton
                        variant="rectangular"
                        border={'1px solid #ccc'}
                        position={'relative'}
                        width={'100%'}
                        height={'100px'}
                        overflow={'hidden'}
                        display={'inline-block'}
                    />
                    <Box mb={1}></Box>
                    <Skeleton
                        variant="rounded"
                        border={'1px solid #ccc'}
                        width={'100%'}
                        height={'30px'}
                    />
                </Box>
            ))}
        </>
    );
}

export { FileBox, LoadingFilesSkeleton };
