import { cloneDeep, isNil } from 'lodash';
import {
    ACCOUNTANT_LEADER,
    ACCOUNTANT_MEMBER,
    COMPLIANCE_LEADER,
    COMPLIANCE_MEMBER,
    CS_LEADER,
    CS_MEMBER,
    BOOKKEEPER_LEADER,
    BOOKKEEPER_MEMBER,
    LEGAL_LEADER,
    LEGAL_MEMBER
} from '../constants';

/**
 * Hàm dùng để phân loại option thành từng loại nhỏ theo role
 * @param supporterOptions {array}
 * @param customerDetail
 * @returns {{bookeepingLeaderOptions: *[], csLeaderOptions: *[], legalLeaderOptions: *[], accountantMemberOptions: *[], complianceLeaderOptions: *[], csMemberOptions: *[], bookeepingMemberOptions: *[], accountantLeaderOptions: *[], legalMemberOptions: *[], complianceMemberOptions: *[]}}
 */
export function classifySupporterByRoles(supporterOptions = [], customerDetail = null) {
    const result = {
        csLeaderOptions: [],
        csMemberOptions: [],
        accountantLeaderOptions: [],
        accountantMemberOptions: [],
        legalLeaderOptions: [],
        legalMemberOptions: [],
        complianceLeaderOptions: [],
        complianceMemberOptions: [],
        bookeepingLeaderOptions: [],
        bookeepingMemberOptions: []
    };

    const isIncludes = {
        csLeaderOption: false,
        csMemberOption: false,
        accountantLeaderOption: false,
        accountantMemberOption: false,
        legalLeaderOption: false,
        legalMemberOption: false,
        complianceLeaderOption: false,
        complianceMemberOption: false,
        bookeepingLeaderOption: false,
        bookeepingMemberOption: false
    };

    /* Xử lý phân option thành các option nhỏ dựa vào role */
    supporterOptions.forEach((supporterOption) => {
        supporterOption.Roles.forEach((role) => {
            if (role.value === CS_LEADER) {
                result.csLeaderOptions.push(supporterOption);
                if (customerDetail && supporterOption.id === customerDetail.cs_leader_id) {
                    isIncludes.csLeaderOption = true;
                }
            }
            if (role.value === CS_MEMBER) {
                result.csMemberOptions.push(supporterOption);
                if (customerDetail && supporterOption.id === customerDetail.cs_member_id) {
                    isIncludes.csMemberOption = true;
                }
            }
            if (role.value === ACCOUNTANT_LEADER) {
                result.accountantLeaderOptions.push(supporterOption);
                if (customerDetail && supporterOption.id === customerDetail.accountant_leader_id) {
                    isIncludes.accountantLeaderOption = true;
                }
            }
            if (role.value === ACCOUNTANT_MEMBER) {
                result.accountantMemberOptions.push(supporterOption);
                if (customerDetail && supporterOption.id === customerDetail.accountant_member_id) {
                    isIncludes.accountantMemberOption = true;
                }
            }
            if (role.value === LEGAL_LEADER) {
                result.legalLeaderOptions.push(supporterOption);
                if (customerDetail && supporterOption.id === customerDetail.legal_leader_id) {
                    isIncludes.legalLeaderOption = true;
                }
            }
            if (role.value === LEGAL_MEMBER) {
                result.legalMemberOptions.push(supporterOption);
                if (customerDetail && supporterOption.id === customerDetail.legal_member_id) {
                    isIncludes.legalMemberOption = true;
                }
            }
            if (role.value === COMPLIANCE_LEADER) {
                result.complianceLeaderOptions.push(supporterOption);
                if (customerDetail && supporterOption.id === customerDetail.compliance_leader_id) {
                    isIncludes.complianceLeaderOption = true;
                }
            }
            if (role.value === COMPLIANCE_MEMBER) {
                result.complianceMemberOptions.push(supporterOption);
                if (customerDetail && supporterOption.id === customerDetail.compliance_member_id) {
                    isIncludes.complianceMemberOption = true;
                }
            }
            if (role.value === BOOKKEEPER_LEADER) {
                result.bookeepingLeaderOptions.push(supporterOption);
                if (
                    customerDetail &&
                    supporterOption.id === customerDetail.internal_bookeeping_leader_id
                ) {
                    isIncludes.bookeepingLeaderOption = true;
                }
            }
            if (role.value === BOOKKEEPER_MEMBER) {
                result.bookeepingMemberOptions.push(supporterOption);
                if (
                    customerDetail &&
                    supporterOption.id === customerDetail.internal_bookeeping_member_id
                ) {
                    isIncludes.bookeepingMemberOption = true;
                }
            }
        });
    });

    /* Xử lý chèn option khi mà customerDetail có option nhưng supporterOptions không có do không nằm trong under role */
    if (!isIncludes.csLeaderOption && customerDetail && customerDetail.CSLeader) {
        result.csLeaderOptions.push(customerDetail.CSLeader);
    }
    if (!isIncludes.csMemberOption && customerDetail && customerDetail.CSMember) {
        result.csMemberOptions.push(customerDetail.CSMember);
    }
    if (!isIncludes.accountantLeaderOption && customerDetail && customerDetail.AccountantLeader) {
        result.accountantLeaderOptions.push(customerDetail.AccountantLeader);
    }
    if (!isIncludes.accountantMemberOption && customerDetail && customerDetail.AccountantMember) {
        result.accountantMemberOptions.push(customerDetail.AccountantMember);
    }
    if (!isIncludes.legalLeaderOption && customerDetail && customerDetail.LegalLeader) {
        result.legalLeaderOptions.push(customerDetail.LegalLeader);
    }
    if (!isIncludes.legalMemberOption && customerDetail && customerDetail.LegalMember) {
        result.legalMemberOptions.push(customerDetail.LegalMember);
    }
    if (!isIncludes.complianceLeaderOption && customerDetail && customerDetail.ComplianceLeader) {
        result.complianceLeaderOptions.push(customerDetail.ComplianceLeader);
    }
    if (!isIncludes.complianceMemberOption && customerDetail && customerDetail.ComplianceMember) {
        result.complianceMemberOptions.push(customerDetail.ComplianceMember);
    }
    if (!isIncludes.bookeepingLeaderOption && customerDetail && customerDetail.BookeepingLeader) {
        result.bookeepingLeaderOptions.push(customerDetail.BookeepingLeader);
    }
    if (!isIncludes.bookeepingMemberOption && customerDetail && customerDetail.BookeepingMember) {
        result.bookeepingMemberOptions.push(customerDetail.BookeepingMember);
    }

    return result;
}

export function listToTree(list) {
    list = cloneDeep(list);
    let map = {},
        node,
        roots = [];

    for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }

    for (let i = 0; i < list.length; i += 1) {
        node = list[i];
        // if you have dangling branches check that map[node.parent_id] exists
        if (node.parent_id && !isNil(map[node.parent_id])) {
            list[map[node.parent_id]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}

export function groupByRole(data = []) {
    return data.reduce((acc, item) => {
        const { Person, Roles, username, id } = item;
        Roles?.forEach((role) => {
            const index = acc.findIndex((i) => i?.id === role?.id);
            if (index !== -1) {
                acc[index].Users.push({ id, Person, username, index });
            } else {
                acc.push({ ...role, Users: [{ id, Person, username }] });
            }
        });
        return acc;
    }, []);
}

export function isSupport(supporterList, person) {
    if(person) {
        if(person?.Roles?.[0]?.value === BOOKKEEPER_LEADER && person.id === supporterList?.internal_bookeeping_leader_id) {
            return true;
        }
        if(person?.Roles?.[0]?.value === BOOKKEEPER_MEMBER && person.id === supporterList?.internal_bookeeping_member_id) {
            return true;
        }
        if(person?.Roles?.[0]?.value === LEGAL_LEADER && person.id === supporterList?.legal_leader_id) {
            return true;
        }
        if(person?.Roles?.[0]?.value === LEGAL_MEMBER && person.id === supporterList?.legal_member_id) {
            return true;
        }
        if(person?.Roles?.[0]?.value === CS_LEADER && person.id === supporterList?.cs_leader_id) {
            return true;
        }
        if(person?.Roles?.[0]?.value === CS_MEMBER && person.id === supporterList?.cs_member_id) {
            return true;
        }
        if(person?.Roles?.[0]?.value === COMPLIANCE_LEADER && person.id === supporterList?.compliance_leader_id) {
            return true;
        }
        if(person?.Roles?.[0]?.value === COMPLIANCE_MEMBER && person.id === supporterList?.compliance_member_id) {
            return true;
        }
        if(person?.Roles?.[0]?.value === ACCOUNTANT_LEADER && person.id === supporterList?.accountant_leader_id) {
            return true;
        }
        if(person?.Roles?.[0]?.value === ACCOUNTANT_MEMBER && person.id === supporterList?.accountant_member_id) {
            return true;
        }
    }
    return false;
}