import { createSlice } from '@reduxjs/toolkit';
import {
    fetchApproveProcess,
    fetchCloseAndRefundProcess,
    fetchGetProcessDetail,
    fetchGetProcessTree,
    fetchGetServiceTypes,
    fetchGetTaskDetail,
    fetchRejectProcess
} from './taskThunk';

const taskSlice = createSlice({
    name: 'task',
    initialState: {
        serviceTypes: [],
        loadingServiceTypes: false,

        processTree: [],
        loadingProcessTree: false,

        /* API get process detail BE trả về Object đi từ Task */
        taskProcess: null, // Task được trả về theo từng process nên data sẽ khác nhau ở từng process - data về task thi giống nhau
        loadingTaskProcess: false,

        taskDetail: null,
        loadingTaskDetail: false,

        selectedStep: null,

        loadingApproveStep: false,
        loadingTerminateStep: false,
        loadingRejectStep: false
    },
    reducers: {
        resetStates: (state) => {
            state.serviceTypes = [];
            state.loadingServiceTypes = false;
            state.processTree = [];
            state.loadingProcessTree = false;
            state.selectedStep = null;
            state.taskProcess = null;
            state.taskDetail = null;
            state.loadingTaskProcess = false;
            state.loadingTaskDetail = false;
            state.loadingApproveStep = false;
            state.loadingTerminateStep = false;
        },
        updateSelectedStep: (state, action) => {
            state.selectedStep = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGetServiceTypes.fulfilled, (state, action) => {
            const changeOfficersServiceTypeIds = [24, 39];
            const isMergeNameServiceType = changeOfficersServiceTypeIds.every(
                (changeOfficersServiceType) =>
                    action.payload.some(
                        (serviceType) => changeOfficersServiceType === serviceType.service_type_id
                    )
            );
            if (isMergeNameServiceType) {
                action.payload = action.payload
                    .filter((serviceType) => {
                        return (
                            serviceType?.service_type_id !==
                            changeOfficersServiceTypeIds[changeOfficersServiceTypeIds.length - 1]
                        );
                    })
                    .map((serviceType) => {
                        if (changeOfficersServiceTypeIds.includes(serviceType.service_type_id)) {
                            return {
                                ...serviceType,
                                name: 'Change Name & Officer'
                            };
                        } else {
                            return serviceType;
                        }
                    });
            }
            state.loadingServiceTypes = false;
            state.serviceTypes = action.payload;
        });
        builder.addCase(fetchGetServiceTypes.pending, (state, action) => {
            state.loadingServiceTypes = true;
        });
        builder.addCase(fetchGetServiceTypes.rejected, (state, action) => {
            state.loadingServiceTypes = false;
        });

        builder.addCase(fetchGetProcessTree.fulfilled, (state, action) => {
            state.loadingProcessTree = false;
            state.processTree = action.payload;
        });
        builder.addCase(fetchGetProcessTree.pending, (state, action) => {
            state.loadingProcessTree = true;
        });
        builder.addCase(fetchGetProcessTree.rejected, (state, action) => {
            state.loadingProcessTree = false;
        });

        builder.addCase(fetchGetProcessDetail.fulfilled, (state, action) => {
            state.loadingTaskProcess = false;
            state.taskProcess = action.payload;
        });
        builder.addCase(fetchGetProcessDetail.pending, (state, action) => {
            state.loadingTaskProcess = true;
        });
        builder.addCase(fetchGetProcessDetail.rejected, (state, action) => {
            state.loadingTaskProcess = false;
            state.taskProcess = null;
        });

        builder.addCase(fetchGetTaskDetail.fulfilled, (state, action) => {
            state.loadingTaskDetail = false;
            state.taskDetail = action.payload;
        });
        builder.addCase(fetchGetTaskDetail.pending, (state, action) => {
            state.loadingTaskDetail = true;
        });
        builder.addCase(fetchGetTaskDetail.rejected, (state, action) => {
            state.loadingTaskDetail = false;
            state.taskDetail = null;
        });

        builder.addCase(fetchApproveProcess.fulfilled, (state, action) => {
            state.loadingApproveStep = false;
        });
        builder.addCase(fetchApproveProcess.pending, (state, action) => {
            state.loadingApproveStep = true;
        });
        builder.addCase(fetchApproveProcess.rejected, (state, action) => {
            state.loadingApproveStep = false;
        });

        builder.addCase(fetchCloseAndRefundProcess.fulfilled, (state, action) => {
            state.loadingTerminateStep = false;
        });
        builder.addCase(fetchCloseAndRefundProcess.pending, (state, action) => {
            state.loadingTerminateStep = true;
        });
        builder.addCase(fetchCloseAndRefundProcess.rejected, (state, action) => {
            state.loadingTerminateStep = false;
        });

        builder.addCase(fetchRejectProcess.fulfilled, (state, action) => {
            state.loadingRejectStep = false;
        });
        builder.addCase(fetchRejectProcess.pending, (state, action) => {
            state.loadingRejectStep = true;
        });
        builder.addCase(fetchRejectProcess.rejected, (state, action) => {
            state.loadingRejectStep = false;
        });
    }
});

const { actions, reducer } = taskSlice;

export const { resetStates, updateSelectedStep } = actions;

export default reducer;
