import axios from 'axios';

const ENDPOINT = 'country';

export const countryApi = {
    getEntityType: (id) => axios.get(`${ENDPOINT}\\${id}\\entity_type`),
    addProposedName: (taskId, payload) =>
        axios.post(`${ENDPOINT}\propose_name\\${taskId}`, payload),
    updateProposedName: (taskId, payload) =>
        axios.put(`${ENDPOINT}\propose_name\\${taskId}`, payload)
};
