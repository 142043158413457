import { createAsyncThunk } from '@reduxjs/toolkit';
import { whatsappApi } from 'api';
import { notificationApi } from 'api/notificationApi';

export const fetchNewsNotification = createAsyncThunk(
    'notification/fetchNewsNotification',
    async (page, thunkAPI) => {
        try {
            const response = await notificationApi.getNotifications({
                offset: page * 10,
                limit: 10,
                filter: { notification_type: 'news' }
            });
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const fetchOthersNotification = createAsyncThunk(
    'notification/fetchOthersNotification',
    async (page, thunkAPI) => {
        try {
            const response = await notificationApi.getNotifications({
                offset: page * 10,
                limit: 10,
                filter: { notification_type: 'others' }
            });
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const fetchMessageNotification = createAsyncThunk(
    'notification/fetchMessageNotification',
    async (page, thunkAPI) => {
        try {
            const response = await notificationApi.getNotifications({
                offset: page * 10,
                limit: 10,
                filter: { notification_type: 'message' }
            });
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const fetchCheckNotification = createAsyncThunk(
    'notification/fetchCheckNotification',
    async (page, thunkAPI) => {
        try {
            const response = await notificationApi.checkNotifications();
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const fetchReadAllNewsNotification = createAsyncThunk(
    'notification/fetchReadAllNewsNotification',
    async (page, thunkAPI) => {
        try {
            const response = await notificationApi.readAll({ notification_type: 'news' });
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const fetchReadAllMessageNotification = createAsyncThunk(
    'notification/fetchReadAllMessageNotification',
    async (page, thunkAPI) => {
        try {
            const response = await notificationApi.readAll({ notification_type: 'message' });
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const fetchReadAllOthersNotification = createAsyncThunk(
    'notification/fetchReadAllOthersNotification',
    async (page, thunkAPI) => {
        try {
            const response = await notificationApi.readAll({ notification_type: 'others' });
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const fetchWhatsappNotification = createAsyncThunk(
    'notification/fetchWhatsappNotification',
    async (page, thunkAPI) => {
        try {
            const response = await whatsappApi.getUnreadMessage({});
            return response;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);
