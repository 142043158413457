import React, { useState } from 'react';
import { Box, Checkbox, IconButton, Tooltip, Typography } from '@mui/material';
import { fileApi } from 'api/fileApi';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Delete, RemoveRedEye } from '@mui/icons-material';
import MConfirmation from 'components/MConfirmation';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { usePermission } from 'hook';
import { TESTER_LEADER } from 'constants';
import moment from 'moment/moment';
import { BOOKKEEPER_ROLES } from 'constants';

/**
 *
 * @param Icon Icon hiển thị ở file
 * @param file object file
 * @param isTemplateFile biến này dùng để check UI hiển thị có phải dạng file template không - 1 dạng UI
 * @param showWithLabel biến này dùng để check xem có show label không - 1 dạng UI
 * @param labelClasses
 * @param disabledDelete
 * @param onReceivePath
 * @param onClickDeleteFile
 * @param onFileDeleteSuccess
 * @param onSelect
 * @param checked
 * @param showDelete
 * @param taskId
 * @param fileTemplateID Dùng để truyền queryParam cho file-viewer
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function File({
    Icon = <InsertDriveFileIcon />,
    file,
    isTemplateFile = false,
    showWithLabel = true,
    labelClasses = '',
    disabledDelete = false,
    onReceivePath,
    onClickDeleteFile,
    onFileDeleteSuccess,
    onSelect,
    checked = false,
    showDelete = true,
    taskId,
    fileTemplateID,
    showViewIcon,
    showTimeLabel = true,
    isHighlight,
    ...props
}) {
    const [loading, setLoading] = useState(false);
    const { hasRoles } = usePermission();

    const handleGetFile = async () => {
        if (!file.s3_path) {
            let check_company_member_id = file.hasOwnProperty('company_member_id');
            let check_to_company_member_id = file.hasOwnProperty('to_company_member_id');
            let check_company_event_id = file.hasOwnProperty('company_event_id');
            // const url = `/file-viewer?file_template&file_template_id=${fileTemplateID}&task_id=${taskId}&company_member_id=${
            //     check_company_member_id ? file.company_member_id : null
            // }`;
            let url = `/file-viewer?file_template&file_template_id=${fileTemplateID}&task_id=${taskId}&company_member_id=${
                check_company_member_id ? file.company_member_id : null
            }&to_company_member_id=${
                check_to_company_member_id ? file.to_company_member_id : null
            }`;
            if (check_company_event_id) {
                url += `&company_event_id=${file.company_event_id}`;
            }
            console.log(url);
            window.open(url);
            return;
        }
        setLoading(true);
        try {
            const result = hasRoles([...BOOKKEEPER_ROLES])
                ? await fileApi.bookkeeperViewFile({ filename_s3: file.s3_path })
                : await fileApi.viewFile({ filename_s3: file.s3_path });
            onReceivePath ? onReceivePath(result.data) : window.open(result.data, '_blank');
        } catch (error) {}
        setLoading(false);
    };

    const handleDeleteFile = async () => {
        if (onClickDeleteFile) {
            onClickDeleteFile();
            return;
        }

        setLoading(true);
        try {
            const result = await fileApi.delete(file.id);
            onFileDeleteSuccess && onFileDeleteSuccess();
        } catch (error) {}
        setLoading(false);
    };

    /* UI này hiển thị cho file template */
    if (isTemplateFile)
        return (
            <div>
                <Box
                    display={'inline-flex'}
                    className={`border border-gray-200 ${
                        isHighlight ? 'bg-sky-200/50' : 'bg-white'
                    } p-1`}
                    borderRadius={1}
                    mb={1}
                    mr={1}
                    alignItems={'center'}>
                    {showDelete && (
                        <Checkbox
                            checked={checked}
                            disabled={disabledDelete || hasRoles(TESTER_LEADER)}
                            onClick={onSelect}
                        />
                    )}

                    <div className="flex flex-col mx-2">
                        <span className="text-sm">{file.name || file.file_name}</span>
                        {showTimeLabel && file?.created_time && (
                            <span className="text-xs">
                                {moment(file?.created_time).format('DD MMM YYYY HH:mm:ss')}
                            </span>
                        )}
                    </div>
                    <IconButton
                        onClick={handleGetFile}
                        size={'small'}
                        disabled={hasRoles(TESTER_LEADER)}
                        color={'primary'}>
                        <RemoveRedEye />
                    </IconButton>
                    {file.id && showDelete && (
                        <MConfirmation
                            title={'Delete file'}
                            disabled={loading || disabledDelete || hasRoles(TESTER_LEADER)}
                            content={`Do you want to delete file ${file.name || file.file_name}`}
                            onApprove={handleDeleteFile}>
                            <IconButton
                                disabled={loading || disabledDelete || hasRoles(TESTER_LEADER)}
                                size={'small'}
                                color={'error'}>
                                <Delete />
                            </IconButton>
                        </MConfirmation>
                    )}
                </Box>
            </div>
        );

    /* UI này hiển thị dạng icon với name */
    if (showWithLabel)
        return (
            <>
                <Tooltip title={file.name || file.file_name}>
                    <Box
                        display={'inline-flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        onClick={handleGetFile}
                        disabled={loading}
                        {...props}
                        className={'hover:bg-slate-50 rounded p-2 hover:cursor-pointer'}>
                        {Icon}
                        <div className="flex flex-col">
                            <p className={'mt-1 truncate max-w-[10rem] ' + labelClasses}>
                                {file.name || file.file_name}
                            </p>
                            {showTimeLabel && file?.created_time && (
                                <span className="text-xs">
                                    {moment(file?.created_time).format('DD MMM YYYY HH:mm:ss')}
                                </span>
                            )}
                        </div>
                    </Box>
                </Tooltip>
                {showViewIcon && (
                    <IconButton
                        onClick={handleGetFile}
                        {...props}>
                        <VisibilityIcon />
                    </IconButton>
                )}
            </>
        );

    /* UI Này chỉ hiển thị icon */
    return (
        <IconButton
            onClick={handleGetFile}
            disabled={loading}
            {...props}>
            {Icon}
        </IconButton>
    );
}

export default React.memo(File);
