import React, { useEffect, useState } from 'react';
import { fileApi } from 'api/fileApi';
import { Avatar, Box, IconButton, Skeleton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { isValidHttpUrl } from 'helper';

/* @todo check in viewport to lazy load image */
/**
 * Component dùng để hiển thị image
 * Có thể truyền s3 path hoặc url
 * @param s3Path string - có thể tuyền s3 path hoặc url của img
 * @param label string - label của img
 * @param imgProps object - nếu isAvatar === true imgProps dùng cho component Avatar, ngược lại dùng cho thẻ img
 * @param isAvatar true|false - để hiển thị image dưới dạng avatar hoặc image
 * @param onRemove function - sự kiện onRemove sử dụng cho một số trường hợp cần remove
 * @param disabled true|false - dùng để disable các sự kiện trên img
 * @returns {JSX.Element}
 * @constructor
 */
function Index({ s3Path, label, imgProps = {}, isAvatar = false, onRemove, disabled = false }) {
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        if (s3Path)
            if (isValidHttpUrl(s3Path)) {
                setUrl(s3Path);
            } else {
                void handleGetFile();
            }
    }, [s3Path]);

    const handleGetFile = async () => {
        setLoading(true);
        try {
            const result = await fileApi.viewFile({ filename_s3: s3Path });
            setUrl(result.data);
        } catch (error) {}
        setLoading(false);
    };

    if (loading)
        return (
            <>
                <Skeleton
                    variant="rectangular"
                    width={200}
                    height={200}
                    {...imgProps}
                />
            </>
        );

    if (isAvatar)
        return (
            <Avatar
                alt={label}
                src={url}
                {...imgProps}
            />
        );

    return (
        <Box
            position={'relative'}
            display={'inline-block'}
            disabed={disabled}>
            <a
                href={url}
                // download={label}
                target={'_blank'}
                rel="noreferrer">
                <img
                    src={url}
                    alt={label}
                    style={{
                        width: '200px',
                        height: '200px',
                        objectFit: 'contain',
                        display: 'inline-block'
                    }}
                    {...imgProps}
                />
            </a>
            {onRemove && (
                <Box
                    position={'absolute'}
                    right={10}
                    bottom={10}>
                    <IconButton
                        size={'small'}
                        disabled={disabled}
                        onClick={onRemove}>
                        <CloseIcon fontSize={'small'} />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
}

export default React.memo(Index);
