import axios from 'axios';

const ENDPOINT = 'task';

export const taskApi = {
    getList: (params) =>
        axios.get(ENDPOINT, {
            params
        }),
    getListAll: (params) =>
        axios.get(ENDPOINT + '/all', {
            params
        }),
    getListOwn: (params) =>
        axios.get(ENDPOINT + '/own', {
            params
        }),
    get: (id) => axios.get(ENDPOINT + '/' + id),
    delete: (id) => axios.delete(ENDPOINT + '/' + id),
    update: (id, data) => axios.put(ENDPOINT + '/' + id, data),
    put: (data) => axios.put(ENDPOINT, data),
    create: (data) => axios.post(ENDPOINT, data),
    getServiceTypes: (id) => axios.get(`${ENDPOINT}/${id}/service_types`),
    getProcessTree: (id, params) => axios.get(`${ENDPOINT}/${id}/processes_tree`, { params }),
    triggerMail: (payload) => axios.put(`${ENDPOINT}/trigger-mail`, payload),
    getFileTemplate: (params) => axios.get(`${ENDPOINT}/file_template`, { params }),
    downloadFileTemplate: (params) =>
        axios.get(`${ENDPOINT}/file_template/download`, { params, responseType: 'blob' }),
    cleanESign: (payload) => axios.put(`${ENDPOINT}/clean_signature`, payload),
    notifyDocs: (endpoint, payload) => axios.post(`${ENDPOINT}/${endpoint}`, payload),
    restore: (id) => axios.put(ENDPOINT + '/restore/' + id),
    getHistoryLog: (id, params) => axios.get(`${ENDPOINT}/${id}/history_log_cdc`, { params }),
    getCompareData: (id) => axios.get(`${ENDPOINT}/${id}/compare_company_info`)
};
