import { useSelector } from 'react-redux';

/**
 * Hook to check role or permission of user
 * @returns {{hasPermissions: ((function(*=, *=): boolean)|*), hasRoles: ((function(*=, *=): (boolean|*))|*)}}
 */
export const usePermission = () => {
    const { RolesMap, PermissionsMap } = useSelector((state) => state.auth);

    /**
     *
     * @param permissions // permission to check - can be string or array
     * @param matchAll // to check match all permission
     * @param rolesForCheck // if it has role map => use it to check instead of RolesMap
     * @returns {boolean}
     */
    const hasPermissions = (permissions, matchAll = false) => {
        if (typeof permissions === 'string') {
            return !!PermissionsMap[permissions];
        }

        if (matchAll) return permissions.every((permission) => !!PermissionsMap[permission]);

        return permissions.some((permission) => !!PermissionsMap[permission]);
    };

    /**
     *
     * @param roles // role to check - can be string or array
     * @param matchAll // to check match all permission
     * @param rolesForCheck // if it has role map => use it to check instead of RolesMap
     * @returns {boolean|*}
     */
    const hasRoles = (roles, matchAll = false, rolesForCheck) => {
        let rolesForCheckMap;
        if (rolesForCheck) {
            rolesForCheckMap = rolesForCheck.reduce((acc, cur) => ({ [cur]: cur, ...acc }), {});
        }

        if (typeof roles === 'string') {
            if (rolesForCheck) return !!rolesForCheckMap[roles];
            return !!RolesMap[roles];
        }

        if (matchAll)
            return roles.every((role) => {
                if (rolesForCheck) return !!rolesForCheckMap[role];
                return !!RolesMap[role];
            });

        return roles.some((role) => {
            if (rolesForCheck) return !!rolesForCheckMap[role];
            return !!RolesMap[role];
        });
    };

    return { hasPermissions, hasRoles };
};
