import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { LS_REMEMBER_PATH } from 'constants/index';

function RequireAuth({ children }) {
    const { access_token, access_token_exp } = useSelector((state) => state.auth);
    let location = useLocation();

    if (!access_token || access_token_exp * 1000 < new Date().getTime()) {
        localStorage.setItem(LS_REMEMBER_PATH, location.pathname + (location.search || ''));

        /* Chỉ lấy access_token và no_admin_layout cho login page */
        const params = new URLSearchParams(location.search);
        let objectParams = {};
        if (params.get('access_token')) {
            objectParams['access_token'] = params.get('access_token');
        }
        if (params.get('no_admin_layout')) {
            objectParams['no_admin_layout'] = params.get('no_admin_layout');
        }
        let queryStringForLoginPage = new URLSearchParams(objectParams).toString();
        return (
            <Navigate
                to={`/auth/login` + (queryStringForLoginPage ? `?${queryStringForLoginPage}` : '')}
                state={{ from: location }}
                replace
            />
        );
    }

    return children;
}

export default RequireAuth;
