import * as bbcincorp from './edit-bbcincorp';
import * as bbcincorpSg from './edit-bbcincorp-sg';
import * as gleads from './edit-gleads';
import * as v2a from './edit-v2a';
import * as arrivalHub from './edit-arrival-hub';

export const CUSTOMER_EDIT = {
    BBCINCORP: bbcincorp.CUSTOMER_EDIT,
    BBCINCORP_SG: bbcincorpSg.CUSTOMER_EDIT,
    GLEADS: gleads.CUSTOMER_EDIT,
    VISA2ASIA: v2a.CUSTOMER_EDIT,
    ARRIVALHUB: arrivalHub.CUSTOMER_EDIT,
};
