import axios from 'axios';

export const WHATSAPP_API_URL = process.env.REACT_APP_WHATSAPP_API_URL;

export const whatsappApi = {
    sendMessage: (data) => axios.post(WHATSAPP_API_URL + '/message', data),
    getMessages: (params) => axios.get(WHATSAPP_API_URL + '/message', { params }),
    uploadFiles: (data) => axios.post(WHATSAPP_API_URL + '/file', data),
    getAllConversation: (params) =>
        axios.get(WHATSAPP_API_URL + '/conversation', {
            params
        }),
    putConversation: (body) => axios.put(WHATSAPP_API_URL + '/conversation', body),
    getConversation: (params) =>
        axios.get(WHATSAPP_API_URL + `/message`, {
            params
        }),
    getUnreadMessage: (params) =>
        axios.get(WHATSAPP_API_URL + `/conversation/unread-user`, {
            params
        }),
    searchAllConversation: (params) =>
        axios.get(WHATSAPP_API_URL + `/search`, {
            params
        }),
    getLabels: (params) =>
        axios.get(WHATSAPP_API_URL + `/label`, {
            params
        }),
    updateName: (id, data) => axios.put(WHATSAPP_API_URL + `/person/${id}`, data),
    getConversationById: (params) =>
        axios.get(WHATSAPP_API_URL + `/conversation/get-by-id`, { params }),
    getThumbnail: (link) => axios.get(WHATSAPP_API_URL + `/message/thumbnails?text=${link}`)
};
