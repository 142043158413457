import React, { useMemo } from 'react';
import { format as formatF, formatDistance } from 'date-fns';
import { DATE_FORMAT } from 'constants/index';
import { isValidDate } from 'helper';

function Index({ date, format = DATE_FORMAT, isAgo = false, agoOptions = {} }) {
    const tDate = useMemo(() => {
        if (!date) return '';

        if (typeof date === 'string') return new Date(date.replaceAll('-', '/')); // Fix for safari

        return date;
    }, [JSON.stringify(date)]);

    if (!tDate) return '';

    if (!isValidDate(tDate)) return '';

    if (isAgo) return formatDistance(tDate, new Date(), agoOptions);

    return formatF(tDate, format);
}

export default React.memo(Index);
